import React, { Fragment } from 'react';
import { connect } from "react-redux";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { removeFromCart } from './../../redux/actions/cartActions.js';

const PublicWrapper = props => (
    <>
        <Header cart={props.cart} removeFromCart={props.removeFromCart} />
        {props.children}
        <Footer />
    </>
);

const mapStateToProps = state => ({
  cart: state.cart.items,
});

export default connect(
  mapStateToProps,
  { removeFromCart }
)(PublicWrapper);
