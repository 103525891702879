import {
  SET_HOME_LOADED_CATEGORIES,
  SET_META,
  SET_ALL_BRANDS,
  SET_HOME_HEADER,
  SET_ORDER_FEEDBACK,
  SET_OFFERS,
  SET_TRENDING,
  SET_RECOMMENDED,
  SET_PREVIOUSLY_VIEWED,
  SET_STORES,
} from '../types';

const initialState = {
  offers: [],
  stores: [],
  trending: [],
  recommended: {},
  previouslyViewed: [],
  brands: [],
  loadedCategories: [],
  header: {},
  meta: {},
  categoriesLoading: false,
  orderFeedback: [],
};

const homepageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HOME_LOADED_CATEGORIES:
      return {
        ...state,
        loadedCategories: [
          ...state.loadedCategories,
          parseInt(action.payload) || parseInt(action.payload.id),
        ],
      };

    case SET_HOME_HEADER:
      return {
        ...state,
        header: action.payload,
      };

    case SET_ALL_BRANDS:
      return {
        ...state,
        brands: action.payload,
      };

    case SET_META:
      return {
        ...state,
        meta: action.payload,
      };
    case SET_ORDER_FEEDBACK:
        return {
            ...state,
            orderFeedback: action.payload,
        }
    case SET_OFFERS:
        return {
            ...state,
            offers: action.payload,
        }
    case SET_TRENDING:
        return {
            ...state,
            trending: action.payload,
        }
    case SET_RECOMMENDED:
        return {
            ...state,
            recommended: action.payload,
        }
    case SET_PREVIOUSLY_VIEWED:
        return {
            ...state,
            previouslyViewed: action.payload,
        }
    case SET_STORES:
        return {
            ...state,
            stores: action.payload,
        }
    default:
      return state;
  }
};

export default homepageReducer;
