/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Input, Icon, message } from 'antd';
import { Link, NavLink } from 'react-router-dom';

import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import AppsIcon from '@material-ui/icons/Apps';
import SearchIcon from '@material-ui/icons/Search';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { subscribeToNewsletter } from '../redux/actions/pageActions';
// import TawkChat from './TawkChat';

const { Search } = Input;

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      intervalId: 0,
      email: null,
    };
    this.subscribe = this.subscribe.bind(this);
  }

  getSocialLinks() {
    const social = this.props.data.socialMedia;
    return social.map(item => (
      <li key={`social-link${item.name}`}>
        <a href={item.link}>
          <Icon type={item.name} />
        </a>
      </li>
    ));
  }

  scrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - 100);
  }

    subscribe(e) {
        if (/\S+@\S+\.\S+/.test(e)) {
            this.props.subscribeToNewsletter({ email: e }, (status, data) => {
                if (status) {
                    message.success(data.data.message);
                    this.setState({email: null});
                } else {
                    try{
                        message.error(data.response.data.error.message);
                    } catch {
                        message.error('Please try again to subscribe');
                    }
                }
            });
        } else {
            message.error('Invalid email format');
        }
    }

//   showTawkToChat = () => {
//     if( window.Tawk_API && window.Tawk_API.getStatus() ) {
//         window.Tawk_API.maximize();
//         // window.Tawk_API.onChatMinimized = function( ) {
//         //     alert(  );
//         //     // window.Tawk_API.hideWidget();
//         // }
//     }
//   }

  render() {
    const {
      phone,
      locations,
      appstore,
      playstore,
      appHeader,
    } = this.props.data;
    const { navLinks: sections, newsletter } = this.props.sections;

    const gutter = 40;
    return (
      <div className="footer">
        <div
          className="subscribe"
          style={{
            backgroundImage: `url("${
              newsletter === undefined ? '' : newsletter.image
            }")`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        >
          <div className="overlay">
            <div className="container">
              <Row gutter={gutter}>
                <Col md={24} lg={24}>
                  <h1> {newsletter === undefined ? '' : newsletter.title} </h1>
                  <h3>{newsletter === undefined ? '' : newsletter.subTitle}</h3>
                  <Search
                    value={this.state.email}
                    placeholder={
                      newsletter === undefined ? '' : newsletter.placeholder
                    }
                    enterButton={
                      newsletter === undefined ? '' : newsletter.buttonText
                    }
                    size="large"
                    onSearch={this.subscribe}
                    onChange={e => this.setState({ email: e.target.value })}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="site-footer">
          <div className="container">
            <Row gutter={gutter}>
              <Col sm={24} md={12} lg={8}>
                <h3>Stay Connected</h3>
                <ul className="inline">
                  {newsletter === undefined ? '' : this.getSocialLinks()}
                </ul>
                <br />

                  <h3>{appHeader && appHeader.title}</h3>
                  <p>
                    <a
                      href={appHeader && appHeader.buttonUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {appHeader && appHeader.buttonText}
                    </a>
                    <br />
                    {appHeader && appHeader.subtitle}
                  </p>

                  <a
                    href={appstore && appstore}
                    target="_blank"
                    rel="noreferrer"
                    className="sprite-image app-store"
                  />
                  <a
                    href={playstore && playstore}
                    target="_blank"
                    rel="noreferrer"
                    className="sprite-image play-store"
                  />
              </Col>

              {sections && sections.map( ( section, index ) => (
                <Col key={`section-${index}`} sm={24} md={6} lg={4}>
                  <h3>{section.title}</h3>
                  <ul>
                    {section.items.map(item => (
                      <li key={`section-${index}-${item.link}`}>
                        <Link to={item.link}> {item.title}</Link>
                      </li>
                    ))}
                  </ul>
                </Col>
              ) )}

              <Col sm={24} md={12} lg={8}>
                <h3>Contact Us</h3>
                <ul>
                  <li>
                    <a href="/">
                      <Icon type="home" /> {locations && locations[0].name}
                    </a>
                  </li>
                  {phone &&
                    phone.map(item => (
                      <li key={item.value}>
                        <a href={`tel:${item.value}`}>
                          <Icon type="phone" /> {item.value}({item.type})
                        </a>
                      </li>
                    ))}
                </ul>
              </Col>
            </Row>
          </div>
        </div>
        <div className="bottom-footer">
          <p>&copy; UG Cakes, All rights reserved. </p>
        </div>
        <BottomNavigation showLabels={true}>
          <BottomNavigationAction
            component={NavLink}
            exact
            to="/"
            activeClassName="active"
            label="Home"
            value=""
            icon={<HomeIcon />}
          />
          <BottomNavigationAction
            component={NavLink}
            to="/categories"
            activeClassName="active"
            label="Categories"
            value="categories"
            icon={<AppsIcon />}
          />
          <BottomNavigationAction
            component={NavLink}
            to="/search"
            activeClassName="active"
            label="Search"
            value="search"
            icon={<SearchIcon />}
          />
          <BottomNavigationAction
            component={NavLink}
            to="/cart"
            activeClassName="active"
            label="Cart"
            value="cart"
            icon={<ShoppingCartIcon />}
          />
          {/* <BottomNavigationAction
            // component={NavLink}
            // to="/cart"
            activeClassName="active"
            label="Chat"
            value="chat"
            onClick={()=>this.showTawkToChat()}
            icon={<ChatIcon />}
          /> */}
        </BottomNavigation>
        {/* <TawkChat /> */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  data: state.footer.items,
  loading: state.page.homeLoading,
  sections: state.footer.items || [],
});

export default connect(mapStateToProps, {
  subscribeToNewsletter,
})(Footer);
