import qs from "qs";

/**
  Link Start From axios baseURL which is defined in api.js in  services folder.
  i.e baseURL+"additional links"

 */
export const newBaseURL = '/api/V2';
export const newBaseURLV3 = '/api/V3';
export const businessBaseURL = '/api/dashboard/v1';

export const staticURL = '';

// auth/user links
export const registerUserLink = 'user-signup';
export const registerCheckLink = 'register/check';
export const loginCheckLink = 'login/check';
export const loginUserLink = 'login/user';
export const fbLoginUserLink = 'login/facebook';
export const googleLoginUserLink = 'login/google';
export const otpLoginUserLink = 'login/otp';
export const forgotPasswordLink = 'forgot-password';
export const changePasswordLink = 'change-password';
export const verifyResetTokenLink = 'verify-reset-token';
export const userConfirmLink = 'user-confirm';
export const currencyConversionLink = 'currency-conversion';
export const resetPasswordLink = 'new-password';
export const resendConfirmationLink = 'resend-confirmation';
export const userProfileLink = 'user-profile';
export const updateUserProfile = 'update-profile';
export const loginWithPhone = 'login-with-phone-number';
export const favoriteProductFetchLink = 'favorite';
export const getShippingAddressLink = 'shipping-addresses';
export const addShippingAddressLink = 'add-shipping-address';
export const updateShippingAddressLink = 'update-shipping-address';
export const removeShippingAdressLink = 'delete-shipping-address';
export const getUserOrdersLink = 'order-listing';
export const getUserOrderDetailLink = 'order-detail';
export const uploadOrderImageLink = 'order-upload-image';
export const deleteOrderImageLink = 'order-delete-image';

export const getSupportTicketsLink = 'support-tickets';
export const getTicketLink = 'ticket';
export const postReplyTicket = 'reply-ticket';

export const redeemVoucherLink = 'redeem-voucher';
// Hme page
export const homepageDataFetchLink = 'page/homepage';

// cart links
export const addToCartLink = '/add-to-cart';
export const updateToCartLink = '/cart';
export const clearCartLink = '/cart/empty';
export const removeFromCartLink = '/remove-from-cart';
export const fetchCartLink = '/cart';
export const updateCartLink = '/update-cart';
export const applyCouponLink = '/apply-coupon';

// favorite links
export const removeFavoriteLink = '/wishes/delete';
export const addToFavoriteLink = '/wishes/add';
export const getFollowLink = '/followers/index';
export const getFavoriteNCartLink = '/wish-and-cart';

// category links
export const getCategoryLink = '/categories';
export const getCategoryTypeLink = 'ug_category_listing/?category_type=';
export const getProductFromCategory = 'category/';
export const getMCategoriesByID = 'mcategory/';

// cakeFinder links

export const getCakeCategoriesLink = 'cake-categories';
export const getSuggestedCakesLink = 'suggested-cakes';

// cart/extra links
export const checkDeliveryLocationEligibilityLink =
  'check-delivery-location-eligibility?';
export const getProductDetail = ( slug, forceInactive ) => `product/${slug}?forceInactive=${forceInactive}`;
export const minCakePoundLink = 'get-min-pound?';
export const cakePriceCalculateLink = 'calculate-product-price?';
export const cakeAccessoryLink = 'cake-accessory';
export const getDeliveryMethodLink = 'delivery-methods-time-slot?';
export const deliveryTimeslotLink = 'time-slot-with-delivery-method?';
export const getCakeShapesLink = 'shapes';
export const firstDeliveryDateLink = 'first-delivery-date?';

// occasion link
export const getOccasionDetails = 'occasion/'; /* occasion/:slug */

// urgent cake links
export const getUrgentProducts = '/products/urgent';

// reviews & complains
export const addComplain = 'complain';
export const addOrderReview = 'order/feedback';
export const getReviewsLink = ( id, type ) => `reviews?id=${id}&type=${type}`;
export const getGalleryLink = ( id, type ) => `galleries?id=${id}&type=${type}`;
export const uploadGalleryLink = `gallery/upload`;
export const viewGalleryLink = 'gallery/view';

// automobile links
export const getAutomobileDetail = 'automobile/detail/';
export const getAutomobileBrandList = 'automobile/brand-list';
export const getAutomobileByBrand = 'automobile/brand/';
export const getAutomobileByType = 'automobile/type/';
export const requestTestDriveLink = 'automobile/test-ride';
export const getAutomobileRelatedProducts = 'automobile/related-products';

// Report link
export const reportLink = 'report';

// blog links
export const getBlogDetailLink = 'blog/';
export const getBlogsLink = 'blogs';
export const getBlogsByCategoryLink = 'blogs/category/';

// product combo link
export const getProductComboLink = 'product-combo/list';

// Token auth link
export const getTokenCheckLink = 'validate-token';

// Delivery locations fetch link
export const fetchAllDeliveryLocationsLink = 'delivery-locations';

// General Page Link
export const getContactUsPageLink = 'contact-us';
export const getCareerPageLink = 'careers';
export const getCareerDetailPageLink = 'career/';
export const postCareerLink = 'career/apply';
export const uploadCareerLink = 'career/upload';
export const getAboutUsPageLink = 'about-us';
export const getFaqPageLink = 'faq';
export const getTermsAndConditionPageLink = 'term-conditions';
export const getPrivacyPolicyPageLink = 'home/privacy-policy';
export const getRefundPolicyPageLink = 'home/refund-policy';
export const getProductPageLink = 'products';
export const getAutomobilePageLink = 'automobile';
export const postContactUsLink = 'home/contact-us';
export const newletterSubscribeLink = 'home/subscribe-newsletter';
export const storesLink = 'home/stores';
export const getHomePageLink = 'home-page';
export const getSellAtUGPageLink = 'sell-at-ug';

export const getDonationProductLink = 'donations';

export const productCollectionLink = slug => `products/${slug}`;

export const fetchOrderFeedbackDetailLink = 'order-feedback';

export const fetchOrderComplainLink = 'order-complain';

// Payment process
export const placeOrderLink = 'place-order';
export const processPaymentLink = 'process-payment';
export const bookAutomobileLink = 'automobile/booking';
export const paymentMethodsLink = 'payment-methods';

export const cancelOrderLink = 'cancellation-request';

export const contestsInfoLink = 'contests';
export const contestListLink = slug => `contest/${slug}`;
export const myContestsLink = 'profile/contests';
export const myContestDetailLink = ( id ) => `profile/contest/${id}`;
export const myApplicationLink = 'profile/applications';
export const getProfileQuizListLink = 'profile/quizzes';
export const updateQuizLink = 'profile/quizzes/update';
export const getQuizLink = 'quiz';
export const getProfileQuizLink = 'profile/quiz';
export const addQuizLink = 'quiz/create';
export const submitAnswerLink = 'quiz/submit';
export const getQuizStatsLink = slug => `profile/quizzes/stats/${slug}`;
export const checkQuizSlugLink = 'quiz/create/check-slug';
export const getQuizScoreLink = id => `quiz/score/${id}`;
// auctions links
export const getAuctions = 'auction';
export const getAuctionDetail = 'auction/';
export const getAuctionBidSuggestionLink = 'auction/bid-suggestion/';
export const getAuctionOfferSuggestionLink = 'auction/offer-suggestion/';
export const getMyAuctionDetail = 'profile/auction/detail';
export const addAuctionLink = 'profile/auction/store';
export const updateAuctionLink = 'profile/auction/update';
export const getMyAuctions = 'profile/auction/my-auction';
export const getMyAuctionSellingBids = 'profile/auction/selling/bids';
export const getMyAuctionSellingOffers = 'profile/auction/selling/offers';
export const acceptOfferLink = 'profile/auction/selling/accept-offer';
export const declineOfferLink = 'profile/auction/selling/decline-offer';
export const getMyOffers = 'profile/auction/my-offer';
export const getMyAuctionBuyingBidLink = 'profile/auction/buying/bids';
export const getMyAuctionBuyingAuctionBidLink = 'profile/auction/buying/bid';
export const getMyAuctionBuyingAuctionOfferLink =
  'profile/auction/buying/offer';
export const updateAuctionImageLink = 'profile/auction/add-images';

// export const getSelfAuctionDetail = 'auction/submit';
export const getAuctionProductSearchLink = 'search';
export const getAuctionProductSelectLink = slug => `auction/product/${slug}`;
export const placeBidLink = 'auction/place-auction-bid';
export const placeOfferBidLink = 'auction/place-offer-bid';

export const uploadFileLink = 'upload/file';

// Comment
/**
 * Returns the Comment Listing Api Link.
 *
 * @param {integer} id Comment ID
 * @param {string} type Comment Type
 * @public
 */
export const getCommentListLink = (id, type, page) => `comments?id=${id}&type=${type}&page=${page}`;
export const getCommentPostLink = 'comment';
export const searchCommentUserLink = q => `comment/tag?q=${q}`;
export const deleteCommentLink = 'comment/delete';
export const getChildCommentLink = ( id, page ) => `comments/${id}?page=${page}`;

/**
 * User Public Profile Link
 *
 * @param {string} username Username.
 */
export const getPublicProfileLink = username => `user/${username}`;
export const connectionsLink = 'connections';
export const connectionSendLink = 'connection/send';
export const connectionAcceptLink = id => `connection/accept/${id}`;
export const connectionDeclineLink = id => `connection/decline/${id}`;
export const connectionHideLink = id => `connection/hide/${id}`;
export const connectionCancelLink = id => `connection/cancel/${id}`;
export const connectionRemoveLink = 'connection/remove';

export const getOrderFeedbackLink = 'home/feedback';
export const getOffersLink = 'products/offers';
export const getTrendingProductLink = 'products/trending';
export const getrecommendedProductLink = 'products/recommended';

export const digTreasureLink = 'treasure/dig';

//Store Links
export const storeDetailsLink = slug => `store/${slug}`;
export const followAddLink = `followers/add`;
export const followDeleteLink = `followers/delete`;

export const multipleProductsLink = `products/multiple`;

// Reaction links
export const addReactionLink = 'reactions/add';
export const removeReactionLink = 'reactions/delete';

// Question links
export const getQuestionListLink = ( id, type ) => `questions?id=${id}&type=${type}`;
export const postQuestionLink = 'question';
export const postAnswerLink = 'answer';

// Campaign Links
export const getCampaignLink = ( slug ) => `campaign/${slug}`;
export const getCampaignOffersLink = `campaign/offers`;
export const getCampaignStoresLink = `campaign/stores`;
export const getCampaignProductsLink = `campaign/products`;

// Business Links
export const beASellerLink = `businesses/store`;

// Roulette
export const getRouletteLink = ( slug ) => `roulette/${slug}`;