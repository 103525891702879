import React from 'react';

function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export default class ImageComponent extends React.Component {

  image = new Image();

  constructor(props) {
    super(props);
    this.state = {
      url: '/images/LoadingImage.webp',
    };
  }

  componentDidMount() {
    this.loadImage( this.props.url );
  }

  componentWillReceiveProps( nextProps ) {
    if( nextProps.url !== this.props.url ) {
        this.loadImage( nextProps.url );
    }
  }

  loadImage = ( url ) => {
    this.image.src = url;
    this.image.onload = () => {
        this.setState( { url } );
    }
    this.image.onerror = () => {
        this.setState( { url: '/images/ImageNotFound.webp' } );
    }
  }

  render() {
    const { children, title, className, style, sizes } = this.props;
    return (
      <img
        src={this.state.url}
        srcSet={this.state.url}
        sizes={sizes || '100vw'}
        alt={title}
        className={className}
        {...children}
        style={style}
      />
    );
  }
}
