import { throttle } from 'lodash';
import {
  SET_DELIVERY_ALIAS,
  REMOVE_FROM_FAVORITE_PRODUCTS,
  UNAUTHENTICATE_USER,
  SET_FAVORITE_PRODUCTS,
  ADD_TO_FAVORITE_PRODUCTS,
  SET_USER_PROFILE,
  SET_USER_DATA,
  AUTHENTICATION_FAILED,
  AUTHENTICATION_SUCCESS,
  SET_USER_DELIVERY_ADDRESSES,
  SET_USER_ORDERS,
  SET_USER_ORDER,
  SET_USER_DELIVERY_LOCATIONS,
  SET_USER_TICKETS,
  SET_USER_TICKET,
  SET_FOLLOW_LIST,
  SET_APPLICATIONS,
  CLEAR_CART_ITEMS,
  FLUSH_AUTOMOBILE_ITEMS,
  ADD_FAVORITE_AUTOMOBILE,
  REMOVE_FAVORITE_AUTOMOBILE,
  SET_CART_ITEMS,
  SET_FAVORITE_AUTOMOBILE,
} from '../types';

import {
  registerUserLink,
  loginUserLink,
  fbLoginUserLink,
  otpLoginUserLink,
  addToFavoriteLink,
  forgotPasswordLink,
  changePasswordLink,
  userProfileLink,
  loginWithPhone,
  getTokenCheckLink,
  fetchAllDeliveryLocationsLink,
  getShippingAddressLink,
  updateShippingAddressLink,
  addShippingAddressLink,
  removeFavoriteLink,
  getUserOrdersLink,
  getUserOrderDetailLink,
  removeShippingAdressLink,
  updateUserProfile as updateUserProfileLink,
  verifyResetTokenLink,
  resetPasswordLink,
  userConfirmLink,
  currencyConversionLink,
  getSupportTicketsLink,
  getTicketLink,
  postReplyTicket,
  resendConfirmationLink,
  googleLoginUserLink,
  getPublicProfileLink,
  connectionSendLink,
  connectionAcceptLink,
  connectionDeclineLink,
  connectionHideLink,
  connectionsLink,
  connectionCancelLink,
  connectionRemoveLink,
  getFollowLink,
  myApplicationLink,
  addReactionLink,
  removeReactionLink, 
  loginCheckLink, registerCheckLink, getFavoriteNCartLink, beASellerLink, redeemVoucherLink
} from '../../services/apiLink';

import { api, apiV2, authBusinessV1, authV2 } from '../../services/api';

export const register = (data, cb = null) => async dispatch => {
    try {
        const user = await apiV2.post(registerUserLink, data);
        dispatch({
            type: AUTHENTICATION_SUCCESS,
        });
        setUserProfile(user.data.data)(dispatch);
        window.localStorage.setItem( 'ugcake_access_token', user.data.data.token );
        if (cb) {
            cb(true, user);
        }
    } catch (error) {
        dispatch({
            type: AUTHENTICATION_FAILED,
        });
        if (cb) {
            cb(false, error.response);
        }
    }
};

export const logout = (cb = null) => async dispatch => {
  try {
    await authV2.post('user-logout');

    localStorage.removeItem('ugcake_access_token');
    localStorage.removeItem('ugcake_headerfooter');
    localStorage.removeItem('automobileCompareIds');
    dispatch({
      type: UNAUTHENTICATE_USER,
    });
    dispatch({
        type: CLEAR_CART_ITEMS,
    });
    dispatch({
        type: SET_FAVORITE_PRODUCTS,
        payload: [],
    });
    dispatch({
        type: FLUSH_AUTOMOBILE_ITEMS,
    });
    if (cb) {
      cb();
    }
  } catch (error) {}
};

export const logoutExclude = ( cb = null ) => async dispatch => {
    try {
        const res = await authV2.post('user-logout?excludeThis=1');
        if (cb) {
            cb( true, res );
        }
    } catch (error) {}
}

export const fetchFollows = (cb = null) =>
  throttle(async dispatch => {
    try {
        const res = await authV2.get(getFollowLink);
        dispatch({
            type: SET_FOLLOW_LIST,
            payload: res.data.data,
        })
        if (cb) {
            cb(true, res);
        }
    } catch (error) {
      if (cb) {
        cb(false, error.response);
      }
    }
  }, 1000);
/**
  Data format
  {
    productId:,
  }
 */
export const addFavoriteProduct = (id, type, cb = null) =>
  throttle(async dispatch => {
    try {
      const res = await authV2.post(addToFavoriteLink, { id, type });
      
      dispatch({
        type: type === 'automobile' ? ADD_FAVORITE_AUTOMOBILE : ADD_TO_FAVORITE_PRODUCTS,
        payload: id,
      });

      if (cb) {
        cb(true, res);
      }
    } catch (error) {
      if (cb) {
        cb(false, error.response);
      }
    }
  }, 1000);

export const removeFavoriteProduct = (id, type, cb = null) =>
  throttle(async dispatch => {
    try {
      const res = await authV2.post(removeFavoriteLink, { id, type });
      dispatch({
        type: type === 'automobile' ? REMOVE_FAVORITE_AUTOMOBILE : REMOVE_FROM_FAVORITE_PRODUCTS,
        payload: id,
      });
      if (cb) {
        cb(true, res);
      }
    } catch (error) {
      if (cb) {
        cb(false, error.response);
      }
    }
  }, 1000);

export const setUserProfile = (data, cb = null) => dispatch => {
  dispatch({
    type: SET_USER_PROFILE,
    payload: data,
  });
  dispatch({
    type: SET_USER_DATA,
    payload: data,
  });

  if (cb) {
    cb();
  }
};

export const login = (data, cb = null) => async dispatch => {
    try {
      const apiDataFormat = {
        ...data,
        source: 'web',
      };
      const user = await apiV2.post(loginUserLink, apiDataFormat);
      dispatch({
        type: AUTHENTICATION_SUCCESS,
      });
      setUserProfile(user.data.data)(dispatch);
      window.localStorage.setItem(
        'ugcake_access_token',
        user.data.data.token
      );

      if (cb) {
        cb(true, user.data.data);
      }
    } catch (error) {
      dispatch({
        type: AUTHENTICATION_FAILED,
      });
      if (cb) {
        cb(false, error.response);
      }
    }
  };

export const otpLogin = ( data, cb = null ) => async dispatch => {
    try {
        const user = await apiV2.post(otpLoginUserLink, data);
        dispatch({
            type: AUTHENTICATION_SUCCESS,
        });
        setUserProfile(user.data.data)(dispatch);
        window.localStorage.setItem(
            'ugcake_access_token',
            user.data.data.token
        );

        if (cb) {
            cb(true, user);
        }
    } catch (error) {
        dispatch({
            type: AUTHENTICATION_FAILED,
        });
        if (cb) {
            cb(false, error.response);
        }
    }
}
export const fbLogin = (data, cb = null) => async dispatch => {
  try {
    const user = await apiV2.post(fbLoginUserLink, data);
    dispatch({
      type: AUTHENTICATION_SUCCESS,
    });
    setUserProfile(user.data.data)(dispatch);
    window.localStorage.setItem(
      'ugcake_access_token',
      user.data.data.token
    );

    if (cb) {
      cb(true, user);
    }
  } catch (error) {
    dispatch({
      type: AUTHENTICATION_FAILED,
    });
    if (cb) {
      cb(false, error.response);
    }
  }
};

export const googleLogin = (data, cb = null) => async dispatch => {
  try {
    const user = await apiV2.post(googleLoginUserLink, data);
    dispatch({
      type: AUTHENTICATION_SUCCESS,
    });
    setUserProfile(user.data.data)(dispatch);
    window.localStorage.setItem(
      'ugcake_access_token',
      user.data.data.token
    );

    if (cb) {
      cb(true, user);
    }
  } catch (error) {
    dispatch({
      type: AUTHENTICATION_FAILED,
    });
    if (cb) {
      cb(false, error.response);
    }
  }
};

export const forgotPassword = ( email, cb = null) => async dispatch => {
  try {
    const res = await apiV2.post( forgotPasswordLink, { email } );
    if (cb) {
      cb(true, res);
    }
  } catch (error) {
    cb(false, error.response);
  }
};

export const changePassword = (data, cb = null) => async dispatch => {
  try {
    const res = await authV2.post(changePasswordLink, data);
    if (cb) {
      cb(true, res);
    }
  } catch (error) {
    cb(false, error.response);
  }
};

export const getUserProfile = ( cb = null ) => async dispatch => {
  try {
    const res = await authV2.get(userProfileLink);
    setUserProfile(res.data.data)(dispatch);

    if (cb) {
      cb(true, res);
    }
  } catch (error) {
    cb(false, error);
  }
};

export const loginWithPhoneNumber = (data, cb = null) => async dispatch => {
  try {
    const res = await apiV2.post(loginWithPhone, data);
    dispatch({
      type: AUTHENTICATION_SUCCESS,
    });

    dispatch({
      type: SET_USER_DATA,
      payload: res.data.data,
    });

    setUserProfile(res.data.data)(dispatch);

    window.localStorage.setItem('ugcake_access_token', res.data.data.token);
    if (cb) {
      cb(true, data);
    }
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
    dispatch({
      type: AUTHENTICATION_FAILED,
    });
  }
};

export const addShippingAddress = (data, cb = null) =>
  throttle(async dispatch => {
    try {
      const res = await authV2.post(addShippingAddressLink, {
        deliveryLocationId: data.deliveryLocationId,
        alias: data.alias,
        phoneNumber: parseInt(data.phoneNumber),
        receiverName: data.receiverName,
        landmark: data.landmark,
        secondaryPhoneNumber: parseInt(data.secondaryPhoneNumber),
        addressId: data.addressId,
        xCoord: data.xCoord,
        yCoord: data.yCoord,
      });
      if (cb) {
        cb(true, res);
      }
    } catch (error) {
      if (cb) {
        cb(false, error.response);
      }
    }
  }, 1000);

export const updateShippingAddress = (data, cb = null) =>
  throttle(async dispatch => {
    try {
      const res = await authV2.post(updateShippingAddressLink, {
        deliveryLocationId: data.deliveryLocationId,
        alias: data.alias,
        phoneNumber: parseInt(data.phoneNumber),
        receiverName: data.receiverName,
        landmark: data.landmark,
        secondaryPhoneNumber: parseInt(data.secondaryPhoneNumber),
        addressId: data.addressId,
        xCoord: data.xCoord,
        yCoord: data.yCoord,
      });
      if (cb) {
        cb(true, res);
      }
    } catch (error) {
      if (cb) {
        cb(false, error.response);
      }
    }
  }, 1000);

export const deleteShippingAddress = (data, cb = null) =>
  throttle(async dispatch => {
    try {
      const res = await authV2.post(removeShippingAdressLink, {
        addressId: data.addressId,
      });
      if (cb) {
        cb(true, res);
      }
    } catch (error) {
      if (cb) {
        cb(false, error.response);
      }
    }
  }, 1000);

export const checkUserAuthStatus = (cb = null) => async dispatch => {
  if (localStorage.getItem('ugcake_access_token') === null) {
    if (cb) {
      dispatch({
        type: AUTHENTICATION_FAILED,
      });
      cb(false);
    }
    return;
  }
  try {
    const res = await authV2.get(getTokenCheckLink);

    dispatch({
      type: SET_USER_DATA,
      payload: res.data.data,
    });

    dispatch({
      type: AUTHENTICATION_SUCCESS,
    });

    setUserProfile(res.data.data)(dispatch);

    if (cb) {
      cb(true, res);
    }
  } catch (error) {
    dispatch({
      type: AUTHENTICATION_FAILED,
    });
    localStorage.removeItem('ugcake_access_token');
    if (cb) {
      cb(false, error.response);
    }
  }
};

export const setUserDeliveryAddress = data => dispatch => {
  dispatch({
    type: SET_USER_DELIVERY_ADDRESSES,
    payload: data,
  });
};

export const getUserDeliveryAddress = (data, cb = null) =>
  throttle(async dispatch => {
    try {
      const res = await authV2.get(`${getShippingAddressLink}/?isDonation=${data.isDonation ?? 0}`);
      const { addressInfo } = res.data.data;
      if( addressInfo ) {
        dispatch({
          type: SET_USER_DELIVERY_ADDRESSES,
          payload: addressInfo,
        });
      }
      if (cb) {
        cb(true, res);
      }
    } catch (error) {
      if (cb) {
        cb(false, error.response);
      }
    }
  }, 1000);

export const setDeliveryAliases = (data, cb = null) => dispatch => {
  dispatch({
    type: SET_DELIVERY_ALIAS,
    payload: data,
  });

  if (cb) {
    cb(data);
  }
};

export const fetchAllDeliveryLocations = (cb = null) =>
  throttle(async dispatch => {
    const cachedDeliveryLocation = localStorage.getItem( 'ug_delivery_location_list' );
    const cachedDeliveryLocationAlias = localStorage.getItem( 'ug_delivery_location_alias' );
    try {
      if( ! cachedDeliveryLocation ) {
        const res = await authV2.get(fetchAllDeliveryLocationsLink);
        
        if (res.data.data) {
          const { deliveryLocations, alias } = res.data.data;

          localStorage.setItem( 'ug_delivery_location_list', JSON.stringify(deliveryLocations) );
          localStorage.setItem( 'ug_delivery_location_alias', JSON.stringify(alias) );
          localStorage.setItem( 'ug_delivery_location_list_expiry', new Date().getTime() + 60 * 60 * 1000 );

          dispatch({
            type: SET_USER_DELIVERY_LOCATIONS,
            payload: deliveryLocations,
          });
          setDeliveryAliases(alias)(dispatch);
        }
        if (cb) {
          cb(true, res);
        }
      } else {
        const deliveryLocations = JSON.parse(cachedDeliveryLocation);
        dispatch({
          type: SET_USER_DELIVERY_LOCATIONS,
          payload: deliveryLocations,
        });
        setDeliveryAliases(JSON.parse(cachedDeliveryLocationAlias))(dispatch);
        if (cb) {
          cb(true, deliveryLocations);
        }
      }
    } catch (error) {
      if (cb) {
        cb(false, error);
      }
    }
  }, 600);

export const fetchAllOrders = ({ page }, cb = null) => async dispatch => {
  try {
    const res = await authV2.get(`${getUserOrdersLink}/?page=${page}`);
    if (res.data.data) {
      const { orders, cancellationReasons } = res.data.data;
      if (orders) {
        dispatch({
          type: SET_USER_ORDERS,
          payload: orders,
          cancellationReasons: cancellationReasons,
          pagination:
            (res.data && res.data.meta && res.data.meta.pagination) || {},
        });
      }
      if (cb) {
        cb(true, res);
      }
    } else if (cb) {
      cb(false, res);
    }
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }
};

export const fetchOrderDetail = (data, cb = null) => async dispatch => {
  try {
    const res = await authV2.get(
      `${getUserOrderDetailLink}?orderId=${data.orderId}${data.status ? `&status=${data.status}` : ``}`
    );

    if (res.data) {
      dispatch({
        type: SET_USER_ORDER,
        payload: res.data,
      });
      if (cb) {
        cb(true, res);
      }
    } else if (cb) {
      cb(false, res);
    }
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }
};

export const fetchSupportTickets = ({ page }, cb = null) => async dispatch => {
  try {
    const res = await authV2.get(`${getSupportTicketsLink}/?page=${page}`);
    if (res.data.data) {
      dispatch({
        type: SET_USER_TICKETS,
        payload: res.data.data,
        pagination:
          (res.data && res.data.meta && res.data.meta.pagination) || {},
      });
      if (cb) {
        cb(true, res);
      }
    } else if (cb) {
      cb(false, res);
    }
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }
};

export const fetchTicket = ({ id }, cb = null) => async dispatch => {
  try {
    const res = await authV2.get(`${getTicketLink}/${id}`);
    if (res.data.data) {
      dispatch({
        type: SET_USER_TICKET,
        payload: res.data.data,
        pagination:
          (res.data && res.data.meta && res.data.meta.pagination) || {},
      });
      if (cb) {
        cb(true, res);
      }
    } else if (cb) {
      cb(false, res);
    }
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }
};

export const replyTicket = (data, cb = null) => async dispatch => {
  try {
    const res = await authV2.post(postReplyTicket, data);
    if (res.data.status == 'success') {
      if (cb) {
        cb(true, res);
      }
    } else {
      if (cb) {
        cb(false, res);
      }
    }
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }
};

export const verifyEmailCode = (data, cb = null) =>
  throttle(async dispatch => {
    try {
      const res = await authV2.post(`verify-email`, data);
      if (cb) {
        cb(true, res);
      }
    } catch (error) {
      if (cb) {
        cb(false, error.response);
      }
    }
  }, 600);

export const verifyPhoneCode = (data, cb = null) =>
  throttle(async dispatch => {
    try {
      const res = await authV2.post(`verify-phone`, data);
      if (cb) {
        cb(true, res);
      }
    } catch (error) {
      if (cb) {
        cb(false, error.response);
      }
    }
  }, 600);

export const updateUserProfile = (data, cb = null) => async dispatch => {
  try {
    const res = await authV2.post(updateUserProfileLink, data);
    setUserProfile(res.data.data)(dispatch);
    if (cb) {
      cb(true, res);
    }
  } catch (error) {
    if (cb) {
      cb(false, error.response);
    }
  }
};

export const verifyResetToken = (token, cb = null) => async dispatch => {
    try {
        const res = await apiV2.post(verifyResetTokenLink, { token });
        if (cb) {
            cb(true, res);
        }
    } catch (error) {
        cb(false, error);
    }
};

export const resetPassword = (data, cb = null) => async dispatch => {
  try {
    const res = await apiV2.post(resetPasswordLink, data);
    if (cb) {
      cb(true, res);
    }
  } catch (error) {
    cb(false, error.response);
  }
};

export const userConfirm = (token, cb = null) => async dispatch => {
  try {
    const res = await apiV2.get(`${userConfirmLink}?token=${token}`);
    cb(true, res);
  } catch (error) {
    cb(false, error.response);
  }
};

export const resendConfirmationCode = ( cb = null ) => async dispatch => {
  try{
    const res = await authV2.post( resendConfirmationLink );
    if( cb ) {
      cb( true, res );
    }
  } catch (error) {
    if( cb ) {
      cb( false, error.response );
    }
  }
}

export const currencyConversion = ( cb = null) => async dispatch => {
  try {
    const res = await apiV2.post(currencyConversionLink);
    if (cb) {
      cb(true, res);
    } else if (cb) {
      cb(false, res);
    }
  } catch (error) {
    if( cb ) {
        cb(false, error);
    }
  }
};

export const getPublicProfile = ( username, cb = null ) => async dispatch => {
  try {
    const res = await authV2.get( getPublicProfileLink(username) );
    if( cb ) {
      cb( true, res );
    }
  } catch ( error ) {
    if( cb ) {
        cb( false, error.response );
    }
  }
}

export const sendConnection = ( username, cb = null ) => async dispatch => {
  try {
    const res = await authV2.post( connectionSendLink, { username } );
    if( cb ) {
      cb( true, res );
    }
  } catch ( error ) {
    if( cb ) {
        cb( false, error.response );
    }
  }
}

export const acceptConnection = ( id, cb = null ) => async dispatch => {
  try {
    const res = await authV2.post( connectionAcceptLink( id ) );
    if( cb ) {
      cb( true, res );
    }
  } catch ( error ) {
    if( cb ) {
        cb( false, error.response );
    }
  }
}

export const declineConnection = ( id, cb = null ) => async dispatch => {
  try {
    const res = await authV2.post( connectionDeclineLink( id ) );
    if( cb ) {
      cb( true, res );
    }
  } catch ( error ) {
    if( cb ) {
        cb( false, error.response );
    }
  }
}

export const hideConnection = ( id, cb = null ) => async dispatch => {
  try {
    const res = await authV2.post( connectionHideLink( id ) );
    if( cb ) {
      cb( true, res );
    }
  } catch ( error ) {
    if( cb ) {
        cb( false, error.response );
    }
  }
}

export const cancelConnection = ( id, cb = null ) => async dispatch => {
    try {
      const res = await authV2.post( connectionCancelLink(id) );
      if( cb ) {
        cb( true, res );
      }
    } catch ( error ) {
        if( cb ) {
            cb( false, error.response );
        }
    }
}

export const removeConnection = ( username, cb = null ) => async dispatch => {
    try {
        const res = await authV2.post( connectionRemoveLink, { username } );
        if( cb ) {
            cb( true, res );
        }
    } catch ( error ) {
        if( cb ) {
            cb( false, error.response );
        }
    }
  }

export const connections = ( cb = null )  => async dispatch => {
    try {
        const res = await authV2.get( connectionsLink );
        if( cb ) {
            cb( true, res );
        }
    } catch ( error ) {
        if( cb ) {
            cb( false, error.response );
        }
    }
}

export const getMyApplication = ( cb = null )  => async dispatch => {
    try {
        const res = await authV2.get( myApplicationLink );
        dispatch({
            type: SET_APPLICATIONS,
            payload: res.data.data,
        });
        if( cb ) {
            cb( true, res );
        }
    } catch ( error ) {
        if( cb ) {
            cb( false, error.response );
        }
    }
}

export const addReaction = ( data, cb = null )  => async dispatch => {
    try {
        const res = await authV2.post( addReactionLink, data );
        if( cb ) {
            cb( true, res );
        }
    } catch ( error ) {
        if( cb ) {
            cb( false, error.response );
        }
    }
}

export const removeReaction = ( data, cb = null )  => async dispatch => {
    try {
        const res = await authV2.post( removeReactionLink, data );
        if( cb ) {
            cb( true, res );
        }
    } catch ( error ) {
        if( cb ) {
            cb( false, error.response );
        }
    }
}

export const loginUserExists = ( data, cb = null )  => async dispatch => {
    try {
        const res = await authV2.post( loginCheckLink, data );
        if( cb ) {
            cb( true, res );
        }
    } catch ( error ) {
        if( cb ) {
            cb( false, error.response );
        }
    }
}

export const registerUserExists = ( data, cb = null )  => async dispatch => {
    try {
        const res = await authV2.post( registerCheckLink, data );
        if( cb ) {
            cb( true, res );
        }
    } catch ( error ) {
        if( cb ) {
            cb( false, error.response );
        }
    }
}

export const updateUserEmail = ( data, cb = null )  => async dispatch => {
    try {
        const res = await authV2.post( 'update-email', data );
        if( cb ) {
            cb( true, res );
        }
    } catch ( error ) {
        if( cb ) {
            cb( false, error.response );
        }
    }
}

export const updateUserPhone = ( data, cb = null )  => async dispatch => {
    try {
        const res = await authV2.post( 'update-phone', data );
        if( cb ) {
            cb( true, res );
        }
    } catch ( error ) {
        if( cb ) {
            cb( false, error.response );
        }
    }
}

export const getBannerInfo = ({ data, resolve, reject }) => {
    try {
        const newData = { contact: 0, type: 'click', ...data };
        const response = authV2.post(`banner/action/click`, newData);
        resolve(response);
    } catch (error) {
        reject(error);
    }
};

export const storeLeadCollectionInfo = ( data, cb = null )  => async dispatch => {
    try {
        const res = await authV2.post( 'banner/action/store', data );
        if( cb ) {
            cb( true, res );
        }
    } catch ( error ) {
        if( cb ) {
            cb( false, error.response );
        }
    }
}

export const fetchFavoriteNCart = (cb = null) => async dispatch => {
    try {
        const res = await authV2.get(getFavoriteNCartLink);
        const { cart, wishlist } = res.data.data;

        dispatch({
            type: SET_CART_ITEMS,
            payload: cart.product,
        });

        dispatch({
            type: SET_FAVORITE_PRODUCTS,
            payload: wishlist.product,
        });

        dispatch({
            type: SET_FAVORITE_AUTOMOBILE,
            payload: wishlist.automobile,
        });

        if (cb) {
            cb(true, res);
        }
    } catch (error) {
        if (cb) {
            cb(false, error);
        }
    }
};

export const beASeller = ( data, cb = null )  => async dispatch => {
    try {
        const res = await authBusinessV1.post( beASellerLink, data );
        if( cb ) {
            cb( true, res );
        }
    } catch ( error ) {
        if( cb ) {
            cb( false, error.response );
        }
    }
}

export const redeemVoucher = ( data, cb = null )  => async dispatch => {
    try {
        const res = await authV2.post( redeemVoucherLink, data );
        if( cb ) {
            cb( true, res );
        }
    } catch ( error ) {
        if( cb ) {
            cb( false, error.response );
        }
    }
}