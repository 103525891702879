import { combineReducers } from 'redux';
import cartReducer from './reducers/cartReducer';
import homepageReducer from './reducers/homepageReducer';
import filterReducer from './reducers/filterReducer';
import userReducer from './reducers/userReducer';
import categoryReducer from './reducers/categoryReducer';
import productReducer from './reducers/productReducer';
import footerReducer from './reducers/footerReducer';
import automobileReducer from './reducers/automobileReducer';
import blogReducer from './reducers/blogReducer';
import pageReducer from './reducers/pageReducer';
import processCartReducer from './reducers/processCartReducer';
import reviewReducer from './reducers/reviewReducer';
import contestReducer from './reducers/contestReducer';
import occasionReducer from './reducers/occasionReducer';
import quizReducer from './reducers/quizReducer';
import auctionReducer from './reducers/auctionReducer';
import commentReducer from './reducers/commentReducer';
import storeReducer from './reducers/storeReducer';
import campaignReducer from './reducers/campaignReducer';
import rouletteReducer from './reducers/rouletteReducer';

export default combineReducers({
  cart: cartReducer,
  home: homepageReducer,
  filter: filterReducer,
  user: userReducer,
  product: productReducer,
  category: categoryReducer,
  footer: footerReducer,
  automobile: automobileReducer,
  blog: blogReducer,
  page: pageReducer,
  processCart: processCartReducer,
  review: reviewReducer,
  contest: contestReducer,
  occasion: occasionReducer,
  quiz: quizReducer,
  auction: auctionReducer,
  comment: commentReducer,
  store: storeReducer,
  campaign: campaignReducer,
  roulette: rouletteReducer,
});
