import React from 'react';
import { Row, Menu, Col } from 'antd';
import { Link, NavLink } from 'react-router-dom';
import Image from '../image/image';

const { SubMenu } = Menu;

const MegaMenu = ({ items }) => {
  function generateMegaList(list) {
    const limitList = list.length > 3 ? list.splice(0, 3, list) : list;

    return (
      limitList.length > 0 &&
      limitList.map(data => {
        if (data.name === undefined) {
          return '';
        }
        return (
          <Menu.Item key={`banner-item-${data.slug}`} style={{ width: '100%' }}>
            <Link to={`/category/${data.slug}`}>
              <span>{data.name}</span>
            </Link>
          </Menu.Item>
        );
      })
    );
  }

  function getMegaList(list) {
    const { children } = list;
    if (list && children.length > 0) {
      return (
        <SubMenu
          key={`sub-menu-${list.slug}`}
          className="mega-menu-container"
          title={
            <span>
              <Link to={`/category/${list.slug}`}>
                <img
                  src={list.imageFileName}
                  alt={list.name}
                  className="menu-image"
                />
                <span>{list.name}</span>
              </Link>
            </span>
          }
        >
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                width: '600px',
              }}
            >
              {children.length > 0 &&
                list.children.map((data, k) => {
                  return (
                    <Menu
                      mode="inline"
                      style={{ width: '50%', height: 'fit-content' }}
                      key={k}
                    >
                        { data.children.length > 0 ? 
                            <Menu.SubMenu
                                key={`sub-menu-${data.slug}`}
                                className="mega-menu-container"
                                title={
                                <span>
                                    <Link to={`/category/${data.slug}`}>
                                    <span>{data.name}</span>
                                    </Link>
                                </span>
                                }
                            >
                                {generateMegaList(data.children)}
                            </Menu.SubMenu>
                            :
                            <li class="ant-menu-submenu ant-menu-submenu-inline mega-menu-container" role="menuitem">
                                <div class="ant-menu-submenu-title" aria-expanded="false" aria-haspopup="true" style={{paddingLeft: '24px'}}>
                                    <span>
                                        <Link to={`/category/${data.slug}`}>
                                            <span>{data.name}</span>
                                        </Link>
                                    </span>
                                </div>
                            </li>
                        }
                    </Menu>
                  );
                })}
            </div>
          </div>
        </SubMenu>
      );
    }
    return (
      <Menu.Item key={list.name}>
        <NavLink activeClassName="active" to={`/category/${list.slug}`}>
          {' '}
          {list.name}
        </NavLink>
      </Menu.Item>
    );
  }

  return (
    <div className="mega-menu">
      <Menu>{items.length > 0 && items.map(data => getMegaList(data))}</Menu>
    </div>
  );
};

export default MegaMenu;
