import {
  ADD_PRODUCT_ITEMS,
  SET_PRODUCT_ITEMS,
  SET_PRODUCT_IS_LOADING,
  SET_PRODUCT_HAS_LOADED,
  FLUSH_PRODUCT_ITEMS,
  SET_PRODUCT_CATEGORIES,
  ADD_PRODUCT_CATEGORIES,
  SET_PRODUCT_ITEM,
  SET_CAKE_ACCESSORY,
  SET_PRODUCT_COMBO,
  SET_PRODUCT_COMPARE,
  ADD_PRODUCT_COMPARE_DATA,
  SET_URGENT_PRODUCTS,
  SET_URGENT_PRODUCTS_IS_LOADING,
  SET_URGENT_PRODUCTS_HAS_LOADED,
  ADD_REVIEW_ON_PRODUCT,
  SET_DONATION_ITEMS,
} from '../types';

const initState = {
  items: [],
  itemsPagination: {},
  loading: false,
  categories: {},
  item: {},
  accessories: [],
  productCombo: [],
  compare: [],
  compareProducts: [],
  urgentProducts: [],
  readyMadeCakes: [],
  urgentProductsLoading: false,
};

const productReducer = (state = initState, action) => {
  switch (action.type) {
    case ADD_PRODUCT_ITEMS:
      return {
        ...state,
        items: [...state.items, ...action.payload],
        itemsPagination: action.pagination,
      };

    case SET_PRODUCT_COMBO:
      return {
        ...state,
        productCombo: action.payload,
      };

    case SET_PRODUCT_COMPARE:
      return {
        ...state,
        compare: action.payload,
      };

    case ADD_PRODUCT_COMPARE_DATA:
      return {
        ...state,
        compareProducts: [...state.compareProducts, ...action.payload],
      };

    case SET_PRODUCT_ITEM:
      return {
        ...state,
        item: action.payload,
      };

    case SET_DONATION_ITEMS:
      return {
        ...state,
        items: action.payload,
      };

    case ADD_PRODUCT_CATEGORIES:
      return {
        ...state,
        categories: [...state.categories, action.payload],
      };

    case SET_PRODUCT_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };

    case SET_CAKE_ACCESSORY:
      return {
        ...state,
        accessories: action.payload,
      };
    case FLUSH_PRODUCT_ITEMS:
      return {
        ...state,
        items: [],
      };

    case SET_PRODUCT_ITEMS:
      return {
        ...state,
        items: action.payload,
      };

    case SET_PRODUCT_IS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case SET_PRODUCT_HAS_LOADED:
      return {
        ...state,
        loading: false,
      };
    case SET_URGENT_PRODUCTS:
      return {
        ...state,
        urgentProducts: [...state.urgentProducts, ...action.payload.products],
        readyMadeCakes:
          (action.payload.readyMadeCakes && action.payload.readyMadeCakes) ||
          [],
        itemsPagination: action.pagination,
      };
    case SET_URGENT_PRODUCTS_IS_LOADING:
      return {
        ...state,
        urgentProductsLoading: true,
      };
    case SET_URGENT_PRODUCTS_HAS_LOADED:
      return {
        ...state,
        urgentProductsLoading: false,
      };

    case ADD_REVIEW_ON_PRODUCT:
      return {
        ...state,
        item: {
          ...state.item,
          reviews: [
            ...state.item.reviews,
            {
              ...action.payload,
            },
          ],
        },
      };
    default:
      return state;
  }
};
export default productReducer;
