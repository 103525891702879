import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Lock} from './Lock.svg';
import { trim } from 'lodash';
import md5 from 'md5';
import './style.scss'

const AppLock = ({children, ...rest}) => {
    const {
        type = 'pin',
        key = 'e3ceb5881a0a1fdaad01296d7554868d',
        enabled = false,
    } = rest;

    const [locked, setLocked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [keyData, setKeyData] = useState('');

    const inputChange = (e) => {
        const data = e.target.value;
        if( 'pin' === type && data !== '' ) {
            const key = parseInt( trim(data) ) + '';
            if( key ) {
                setKeyData( key );
            }
        } else {
            setKeyData( data );
        }
    }

    useEffect(() => {
        if( enabled ) {
            const hashKey = sessionStorage.getItem( 'app_lock_hash' );
            if( hashKey !== key ) {
                setLocked( true );
            }
        }
    }, [enabled])

    const handleSubmit = ( e ) => {
        e.preventDefault();
        if( keyData !== '' ) {
            setLoading( true );
            const userKey = md5(keyData);
            if( userKey === key ) {
                sessionStorage.setItem( 'app_lock_hash', userKey );
                setLocked( false );
            }
            setLoading( false );
        }
    }

    if( ! locked || ! enabled ) {
        return children;
    }

    return (
        <div className='applock'>
            <div className='container'>
                <form onSubmit={handleSubmit}>
                    <Lock className='lock'/>
                    <h1>Protected Page</h1>
                    <input name='key' type='password' onChange={inputChange} value={keyData} placeholder={ 'pin' === type ? 'Enter your Pin' : 'Enter your password'} autoFocus />
                    <button disabled={loading}>Submit</button>
                </form>
            </div>
        </div>
    )
}

AppLock.propTypes = {
    type: PropTypes.oneOf(['pin', 'password']),
    key: PropTypes.string,
    enabled: PropTypes.bool,
}

export default AppLock
