import React, { Component } from 'react';
import UGContext from './UGContext';
import config from './index';

class UGProvider extends Component {
    render() {
        return (
            <UGContext.Provider value={config}>
                {this.props.children}
            </UGContext.Provider>
        )
    }
}

export default UGProvider;