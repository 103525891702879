import React from 'react'
import PropType from 'prop-types';
import { Link } from 'react-router-dom'
import history from '../../router/history'

const StateLink = props => {
    const handleLogin = (e) => {
        e.preventDefault();
        let referrer = window.location.href;
        referrer = referrer.replace( window.location.origin, '' );
        history.push({
            pathname: props.to || '/',
            state: history.location.state || { referrer },
        });
    }
    return (
        <Link to={props.to || '/'} onClick={handleLogin}>
            {props.children}
        </Link>
    )
}

StateLink.propTypes = {
    to: PropType.string.isRequired,
}

export default StateLink
