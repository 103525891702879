import {
  SET_PAGE_ABOUT_US,
  SET_PAGE_PRIVACY_POLICY,
  SET_PAGE_REFUND_POLICY,
  SET_PAGE_TERMS_CONDITIONS,
  SET_PAGE_FAQ,
  SET_PAGE_ERROR,
  SET_PAGE_HOME,
  SET_PAGE_PRODUCT,
  SET_PAGE_AUTOMOBILE,
  SET_PAGE_CONTACT_US,
  SET_PAGE_CAREER,
  SET_PAGE_CAREER_DETAIL,
  SET_PAGE_HOMELOADING_TRUE,
  SET_PAGE_HOMELOADING_FALSE,
  SET_PAGE_FEEDBACK_DETAIL,
  SET_PAGE_SELL_AT_UG,
} from '../types';

const initialState = {
  about: {},
  error: {},
  privacyPolicy: '',
  refundPolicy: '',
  termsAndConditions: {},
  faqs: {},
  contactUs: {
    banner: {},
    content: {},
  },
  career: null,
  careerDetail: {
    banner: {},
    content: {},
  },
  feedbackDetail: {
    banner: {},
    content: {},
  },
  product: [],
  automobile: {
    sliders: [],
    vehicleOfMonth: null,
    bookWithUs: null,
    ads: [],
  },
  searchText: '',
  homeLoading: false,
  home: null,
  sellAtUGPage: {},
};

const pageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAGE_ABOUT_US:
      return {
        ...state,
        about: action.payload,
      };
    case SET_PAGE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_PAGE_CONTACT_US:
      return {
        ...state,
        contactUs: action.payload,
      };
    case SET_PAGE_CAREER:
      return {
        ...state,
        career: action.payload,
      };
    case SET_PAGE_CAREER_DETAIL:
      return {
        ...state,
        careerDetail: action.payload,
      };
    case SET_PAGE_FEEDBACK_DETAIL:
      return {
        ...state,
        feedbackDetail: action.payload,
      };
    case SET_PAGE_HOMELOADING_TRUE:
      return {
        ...state,
        homeLoading: true,
      };
    case SET_PAGE_HOMELOADING_FALSE:
      return {
        ...state,
        homeLoading: false,
      };
    case SET_PAGE_HOME:
      return {
        ...state,
        home: action.payload,
        homeLoading: false,
      };
    case SET_PAGE_AUTOMOBILE:
      return {
        ...state,
        automobile: action.payload,
      };

    case SET_PAGE_PRODUCT:
      return {
        ...state,
        product: action.payload,
      };

    case SET_PAGE_PRIVACY_POLICY:
      return {
        ...state,
        privacyPolicy: action.payload,
      };
    case SET_PAGE_REFUND_POLICY:
      return {
        ...state,
        refundPolicy: action.payload,
      };

    case SET_PAGE_TERMS_CONDITIONS:
      return {
        ...state,
        termsAndConditions: action.payload,
      };

    case SET_PAGE_FAQ:
      return {
        ...state,
        faqs: action.payload,
      };
    
    case SET_PAGE_SELL_AT_UG:
        return {
            ...state,
            sellAtUGPage: action.payload,
        }
    default:
      return state;
  }
};

export default pageReducer;
