import React from 'react';
import { Icon } from 'antd';

const PublicProfileSvg = () => (
    <svg width="1em" height="1em" fill='currentColor' viewBox="0 0 37.994 32.627">
        <g transform="translate(-67.055 -190.995)"><path d="M93.532,408.8a1.525,1.525,0,0,1-.561.166,12.927,12.927,0,0,0-7.2,4.936,3.639,3.639,0,0,0-.815,1.473,2.3,2.3,0,0,0,2.524,2.783.819.819,0,0,1,.917.492c.4.671.294.6-.364.653-2.146.156-4.295.251-6.445.214a56.5,56.5,0,0,1-10.818-1.006,17.946,17.946,0,0,1-3.253-.983.638.638,0,0,1-.46-.661,11.409,11.409,0,0,1,2.4-6.744,7.055,7.055,0,0,1,2.5-1.9c1.569-.759,3.186-1.406,4.8-2.059a.476.476,0,0,1,.661.185,6.367,6.367,0,0,0,3.745,2.487,5.609,5.609,0,0,0,4.932-1.344,6.475,6.475,0,0,0,1.137-1.208c.121-.166.24-.258.462-.169a55.32,55.32,0,0,1,5.541,2.432C93.348,408.606,93.49,408.641,93.532,408.8Z" transform="translate(0 -198.053)" /><path d="M181.375,198.336a3.006,3.006,0,0,1-.005.513.686.686,0,0,0,.239.7,1.308,1.308,0,0,1,.344.864,5.56,5.56,0,0,1-1.366,4.326,3.958,3.958,0,0,0-.7,1.217,6.445,6.445,0,0,1-1.945,2.841,4.9,4.9,0,0,1-7.271-.8,6.842,6.842,0,0,1-1.218-2.205,2.664,2.664,0,0,0-.525-.913,5.653,5.653,0,0,1-1.479-4.445,1.425,1.425,0,0,1,.468-1.013.577.577,0,0,0,.168-.5c.023-.83-.05-1.665.053-2.491a5.7,5.7,0,0,1,4.786-5.236,8.329,8.329,0,0,1,5.437.661,2.991,2.991,0,0,1,1.755,2.2.69.69,0,0,0,.162.395,4.036,4.036,0,0,1,1.03,2.472A5.958,5.958,0,0,1,181.375,198.336Z" transform="translate(-92.406 0)" /><path d="M323.2,456.621c-.24-.331-.486-.659-.751-.97a11.444,11.444,0,0,0-8.364-4.419,9.814,9.814,0,0,0-2.955.4,12.164,12.164,0,0,0-6.573,5.131,1.178,1.178,0,0,0,.236,1.635,1.472,1.472,0,0,0,1.861.089c.358-.278.358-.278.523.139a7.238,7.238,0,0,0,13.5.006c.136-.342.214-.436.542-.153a1.462,1.462,0,0,0,1.918-.154A1.175,1.175,0,0,0,323.2,456.621Zm-3.957.039a5.352,5.352,0,0,1-10.63-.067.491.491,0,0,1,.135-.469,8.244,8.244,0,0,1,1.265-.949c.076-.046.165-.121.251-.046s.032.173.021.263a3.689,3.689,0,1,0,7.319.134c-.009-.12-.12-.28.042-.356.112-.053.222.061.314.129.363.264.718.536,1.079.8A.54.54,0,0,1,319.245,456.66Z" transform="translate(-218.486 -239.629)" /></g>
    </svg>
)

const PublicProfileIcon = props => <Icon component={PublicProfileSvg} {...props} />
export default PublicProfileIcon;