import {
  ADD_CART_ITEMS,
  SET_CART_ITEMS,
  CLEAR_CART_ITEMS,
  UPDATE_CART_ITEMS,
  REMOVE_CART_ITEMS,
  CART_IS_LOADING,
  SET_CART_DELIVERY_METHOD,
  SET_CART,
  SET_CART_ID,
  CART_HAS_LOADED,
  SET_CART_TIMESLOTS,
  SET_CART_CAKE_ACCESSORY_ITEMS,
  SET_CART_CURRENT_DELIVERY_ADDRESS,
  SET_CART_CURRENT_DELIVERY_METHOD,
  SET_CART_PAYMENT_METHODS,
} from '../types';

// accessoryToApi contains the cake accessory that will be sent
// when the user adds the cake to cart in the same delivery address
const initState = {
  items: [],
  deliveryMethod: [],
  timeslots: [],
  loading: false,
  cart: [],
  accessoryToApi: [],
  currentDeliveryAddress: {},
  currentDeliveryMethod: {},
  paymentMethods: [],
  defaultPaymentMethod: '',
  cartId: [],
  unpaidOrders: [],
  cartNote: null,
  gifts: [],
};

const cartReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_CART_CURRENT_DELIVERY_ADDRESS:
      return {
        ...state,
        currentDeliveryAddress: action.payload,
      };
    case SET_CART_CURRENT_DELIVERY_METHOD:
      return {
        ...state,
        currentDeliveryMethod: action.payload,
      };
    case ADD_CART_ITEMS:
      return {
        ...state,
        items: [...state.items, action.payload],
      };

    case SET_CART_ITEMS:
      return {
        ...state,
        items: action.payload,
      };

    case SET_CART_TIMESLOTS:
      return {
        ...state,
        timeslots: action.payload,
      };

    case CLEAR_CART_ITEMS:
      return {
        ...state,
        items: [],
      };

    case UPDATE_CART_ITEMS:
      return {
        ...state,
        items: action.payload,
      };

    case REMOVE_CART_ITEMS:
      return {
        ...state,
        items: action.payload,
      };

    case CART_HAS_LOADED:
      return {
        ...state,
        loading: false,
      };

    case CART_IS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case SET_CART_DELIVERY_METHOD:
      return {
        ...state,
        deliveryMethod: action.payload,
      };

    case SET_CART:
      return {
        ...state,
        cart: action.payload,
        cartNote: action.note || null,
        unpaidOrders: action.unpaidOrders || [],
        gifts: action.gifts || [],
      };
    case SET_CART_CAKE_ACCESSORY_ITEMS:
      return {
        ...state,
        accessoryToApi: action.payload,
      };

    case SET_CART_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.payload,
        defaultPaymentMethod: action.default,
      };
    case SET_CART_ID:
      return {
        ...state,
        cartId: parseInt(action.payload),
      };
    default:
      return state;
  }
};
export default cartReducer;
