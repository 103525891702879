import {
  UNAUTHENTICATE_USER,
  SET_FAVORITE_PRODUCTS,
  ADD_TO_FAVORITE_PRODUCTS,
  REMOVE_FROM_FAVORITE_PRODUCTS,
  SET_USER_PROFILE,
  SET_USER_DELIVERY_ADDRESSES,
  AUTHENTICATION_SUCCESS,
  SET_USER_DATA,
  SET_USER_ORDERS,
  AUTHENTICATION_FAILED,
  SET_USER_ORDER,
  SET_USER_DELIVERY_LOCATIONS,
  SET_DELIVERY_ALIAS,
  SET_USER_TICKETS,
  SET_USER_TICKET,
  SET_APPLICATIONS,
  SET_FOLLOW_LIST
} from '../types';
// Data is used for order detail data
const initialState = {
  isLoggedIn: false,
  favorites: [],
  orders: [],
  tickets: [],
  ticket: {},
  pagination: {},
  profile: {},
  data: null,
  order: {},
  deliveryLocations: [],
  deliveryAddresses: [],
  alias: {},
  applications: [],
  cancellationReasons: [],
  followList: {
    business: [],
  },
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATION_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
      };
    case SET_DELIVERY_ALIAS:
      return {
        ...state,
        alias: action.payload,
      };
    case SET_USER_ORDERS:
      return {
        ...state,
        orders: action.payload,
        cancellationReasons: action.cancellationReasons,
        pagination: action.pagination,
      };

    case SET_USER_ORDER:
      return {
        ...state,
        order: action.payload,
      };

    case SET_USER_TICKETS:
      return {
        ...state,
        tickets: action.payload,
        pagination: action.pagination,
      };

    case SET_USER_TICKET:
      return {
        ...state,
        ticket: action.payload,
        pagination: action.pagination,
      };

    case UNAUTHENTICATE_USER:
      return {
        ...state,
        isLoggedIn: false,
      };

    case SET_USER_DELIVERY_LOCATIONS:
      return {
        ...state,
        deliveryLocations: action.payload,
      };
    case SET_FAVORITE_PRODUCTS:
      return {
        ...state,
        favorites: action.payload,
      };

    case ADD_TO_FAVORITE_PRODUCTS:
      return {
        ...state,
        favorites: [...state.favorites, action.payload],
      };

    case REMOVE_FROM_FAVORITE_PRODUCTS:
      return {
        ...state,
        favorites: state.favorites.filter(
          item => parseInt(item) !== parseInt(action.payload)
        ),
      };

    case SET_USER_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };

    case SET_USER_DATA:
      return {
        ...state,
        data: action.payload,
      };

    case SET_USER_DELIVERY_ADDRESSES:
      return {
        ...state,
        deliveryAddresses: action.payload,
      };
    case AUTHENTICATION_FAILED:
      return {
        ...state,
        favorites: [],
        profile: {},
        data: null,
        deliveryLocations: [],
        deliveryAddresses: [],
        isLoggedIn: false,
      };
    case SET_APPLICATIONS:
        return {
            ...state,
            applications: action.payload,
        }
    case SET_FOLLOW_LIST:
        return {
            ...state,
            followList: action.payload,
        }
    default:
      return state;
  }
};
export default userReducer;
