export const ADD_CART_ITEMS = 'cart/ADD_CART_ITEMS';
export const CLEAR_CART_ITEMS = 'cart/CLEAR_CART_ITEMS';
export const UPDATE_CART_ITEMS = 'cart/UPDATE_CART_ITEMS';
export const REMOVE_CART_ITEMS = 'cart/REMOVE_CART_ITEMS';
export const ADD_CART_ITEMS_FAILED = 'cart/ADD_CART_ITEMS_FAILED';
export const CART_HAS_LOADED = 'cart/CART_HAS_LOADED';
export const CART_IS_LOADING = 'cart/CART_IS_LOADING';
export const SET_CART = 'cart/SET_CART';
export const SET_CART_DELIVERY_METHOD = 'cart/SET_CART_DELIVERY_METHOD';
export const SET_CART_TIMESLOTS = 'cart/SET_CART_TIMESLOTS';
export const SET_CART_ITEMS = 'cart/SET_CART_ITEMS';
export const SET_CART_CAKE_ACCESSORY_ITEMS =
  'cart/SET_CART_CAKE_ACCESSORY_ITEMS';
export const SET_CART_CURRENT_DELIVERY_ADDRESS =
  'cart/SET_CART_CURRENT_DELIVERY_ADDRESS';
export const SET_CART_CURRENT_DELIVERY_METHOD =
  'cart/SET_CART_CURRENT_DELIVERY_METHOD';
export const SET_CART_PAYMENT_METHODS = 'cart/SET_CART_PAYMENT_METHODS';
export const SET_CART_ID = 'cart/SET_CART_ID';

// Filter Action Type
export const ADD_FILTER_SIZE = 'filter/ADD_FILTER_SIZE';
export const ADD_FILTER_COLOR = 'filter/ADD_FILTER_COLOR';
export const ADD_FILTER_BRAND = 'filter/ADD_FILTER_BRAND';
export const CLEAR_FILTERS = 'filter/CLEAR_FILTERS';
export const CHANGE_FILTER_MAX_PRICE = 'filter/CHANGE_FILTER_MAX_PRICE';
export const CHANGE_FILTER_MIN_PRICE = 'filter/CHANGE_FILTER_MIN_PRICE';
export const SET_FILTER_FILTER_BOX = 'filter/SET_FILTER_FILTER_BOX';

// User rootReducer
export const SET_USER_ORDER = 'user/SET_USER_ORDER';
export const AUTHENTICATE_USER = 'user/AUTHENTICATE_USER';
export const UNAUTHENTICATE_USER = 'user/UNAUTHENTICATE_USER';
export const SET_FAVORITE_PRODUCTS = 'user/SET_FAVORITE_PRODUCTS';
export const ADD_TO_FAVORITE_PRODUCTS = 'user/ADD_TO_FAVORITE_PRODUCTS';
export const REMOVE_FROM_FAVORITE_PRODUCTS =
  'user/REMOVE_FROM_FAVORITE_PRODUCTS';
export const SET_USER_PROFILE = 'user/SET_USER_PROFILE';
export const AUTHENTICATING_USER = 'user/AUTHENTICATING_USER';
export const AUTHENTICATION_SUCCESS = 'user/AUTHENTICATION_SUCCESS';
export const AUTHENTICATION_FAILED = 'user/AUTHENTICATION_FAILED';
export const SET_USER_DATA = 'user/SET_USER_DATA';
export const SET_USER_DELIVERY_ADDRESSES = 'user/SET_USER_DELIVERY_ADDRESSES';
export const SET_USER_DELIVERY_LOCATIONS = 'user/SET_USER_DELIVERY_LOCATIONS';
export const SET_USER_ORDERS = 'user/SET_USER_ORDERS';
export const SET_DELIVERY_ALIAS = 'user/SET_DELIVERY_ALIAS';
export const SET_APPLICATIONS = 'user/SET_APPLICATIONS';
export const SET_FOLLOW_LIST = 'store/SET_FOLLOW_LIST';

// Homepage reducer
export const SET_HOME_HEADER = 'home/SET_HOME_HEADER';
export const SET_HOME_LOADED_CATEGORIES = 'home/SET_HOME_LOADED_CATEGORIES';
export const SET_NEW_ARRIVALS = 'home/SET_NEW_ARRIVALS';
export const SET_META = 'home/SET_META';
export const SET_ALL_BRANDS = 'home/SET_ALL_BRANDS';
export const SET_ORDER_FEEDBACK = 'home/SET_ORDER_FEEDBACK';
export const SET_OFFERS = 'home/SET_OFFERS';
export const SET_TRENDING = 'home/SET_TRENDING';
export const SET_RECOMMENDED = 'home/SET_RECOMMENDED';
export const SET_PREVIOUSLY_VIEWED = 'home/SET_PREVIOUSLY_VIEWED';
export const SET_STORES = 'home/SET_STORES';

// Product reducer
export const ADD_PRODUCT_ITEMS = 'product/ADD_PRODUCT_ITEMS';
export const SET_PRODUCT_ITEMS = 'product/SET_PRODUCT_ITEMS';
export const SET_PRODUCT_IS_LOADING = 'product/SET_PRODUCT_IS_LOADING';
export const SET_PRODUCT_HAS_LOADED = 'product/SET_PRODUCT_HAS_LOADED';
export const FLUSH_PRODUCT_ITEMS = 'product/FLUSH_PRODUCT_ITEMS';
export const SET_PRODUCT_CATEGORIES = 'product/SET_PRODUCT_CATEGORIES';
export const ADD_PRODUCT_CATEGORIES = 'product/ADD_PRODUCT_CATEGORIES';
export const SET_PRODUCT_ITEM = 'product/SET_PRODUCT_ITEM';
export const SET_CAKE_ACCESSORY = 'product/SET_CAKE_ACCESSORY';
export const SET_PRODUCT_COMPARE = 'product/SET_PRODUCT_COMPARE';
export const ADD_PRODUCT_COMPARE_DATA = 'product/ADD_PRODUCT_COMPARE_DATA';
export const SET_DONATION_ITEMS = 'product/SET_DONATION_ITEMS';

// Auction reducer
export const SET_AUCTION_IS_LOADING = 'auction/SET_AUCTION_IS_LOADING';
export const SET_AUCTION_HAS_LOADED = 'auction/SET_AUCTION_HAS_LOADED';
export const SET_AUCTION_ITEMS = 'auction/SET_AUCTION_ITEMS';
export const SET_MY_AUCTION_ITEMS = 'auction/SET_MY_AUCTION_ITEMS';
export const SET_MY_BID_FOR_AUCTION = 'auction/SET_MY_BID_FOR_AUCTION';
export const SET_AUCTION_ITEM = 'auction/SET_AUCTION_ITEM';
export const SET_SELF_AUCTION_ITEM = 'auction/SET_SELF_AUCTION_ITEM';
export const SET_AUCTION_PRODUCT_SEARCH = 'auction/SET_AUCTION_PRODUCT_SEARCH';
export const SET_AUCTION_PRODUCT_SELECT = 'auction/SET_AUCTION_PRODUCT_SELECT';
export const SET_AUCTION_ERROR = 'auction/SET_AUCTION_ERROR';

// Urgent Cakes - Product Reducer
export const SET_URGENT_PRODUCTS = 'cakes/SET_URGENT_PRODUCTS';
export const SET_URGENT_PRODUCTS_IS_LOADING = 'cakes/SET_URGENT_PRODUCTS_IS_LOADING';
export const SET_URGENT_PRODUCTS_HAS_LOADED = 'cakes/SET_URGENT_PRODUCTS_HAS_LOADED';

// Review reducer
export const ADD_REVIEW = 'review/ADD_REVIEW';
export const REVIEW_SUCCESS = 'review/REVIEW_SUCCESS';
export const REVIEW_ERROR = 'review/REVIEW_ERROR';
export const ADD_REVIEW_ON_PRODUCT = 'review/ADD_REVIEW_ON_PRODUCT';

export const ADD_COMPLAIN = 'review/ADD_COMPLAIN';
export const COMPLAIN_SUCCESS = 'review/COMPLAIN_SUCCESS';
export const COMPLAIN_ERROR = 'review/COMPLAIN_ERROR';

// category reducer
export const CLEAR_CATEGORY_ITEMS = 'category/CLEAR_CATEGORY_ITEMS';
export const SET_CATEGORY_IS_LOADING = 'category/SET_CATEGORY_IS_LOADING';
export const SET_CATEGORY_HAS_LOADED = 'category/SET_CATEGORY_HAS_LOADED';
export const SET_CATEGORY_ITEMS = 'category/SET_CATEGORY_ITEMS';
export const SET_CATEGORY_BRANDS = 'category/SET_CATEGORY_BRANDS';

// event reducer
export const SET_EVENT_ITEMS = 'event/SET_EVENT_ITEMS,';
export const SET_EVENT_IS_LOADING = 'event/SET_EVENT_IS_LOADING,';
export const SET_EVENT_HAS_LOADED = 'event/SET_EVENT_HAS_LOADED,';
export const SET_EVENT_ITEM = 'event/SET_EVENT_ITEM';

// ticket handler
export const SET_USER_TICKETS = 'user/SET_USER_TICKETS';
export const SET_USER_TICKET = 'user/SET_USER_TICKET';


// occasion reducer
export const SET_OCCASION_ITEMS = 'occasion/SET_OCCASION_ITEMS';
export const SET_OCCASION_IS_LOADING = 'occasion/SET_OCCASION_IS_LOADING,';
export const SET_OCCASION_HAS_LOADED = 'occasion/SET_OCCASION_HAS_LOADED,';

// footer reducer
export const SET_FOOTER_ITEMS = 'footer/SET_FOOTER_ITEMS';
export const FOOTER_IS_LOADING = 'footer/FOOTER_IS_LOADING';
export const FOOTER_HAS_LOADED = 'footer/FOOTER_HAS_LOADED';

// Automobile reducer
export const ADD_AUTOMOBILE_ITEMS = 'automobile/ADD_AUTOMOBILE_ITEMS';
export const SET_AUTOMOBILE_ITEMS = 'automobile/SET_AUTOMOBILE_ITEMS';
export const SET_AUTOMOBILE_IS_LOADING = 'automobile/SET_AUTOMOBILE_IS_LOADING';
export const SET_AUTOMOBILE_HAS_LOADED = 'automobile/SET_AUTOMOBILE_HAS_LOADED';
export const FLUSH_AUTOMOBILE_ITEMS = 'automobile/FLUSH_AUTOMOBILE_ITEMS';
export const SET_AUTOMOBILE_CATEGORIES = 'automobile/SET_AUTOMOBILE_CATEGORIES';
export const ADD_AUTOMOBILE_CATEGORIES = 'automobile/ADD_AUTOMOBILE_CATEGORIES';
export const SET_AUTOMOBILE_ITEM = 'automobile/SET_AUTOMOBILE_ITEM';
export const SET_AUTOMOBILE_BRAND = 'automobile/SET_AUTOMOBILE_BRAND';
export const SET_AUTOMOBILE_BRANDLIST = 'automobile/SET_AUTOMOBILE_BRANDLIST';
export const SET_AUTOMOBILE_TYPELIST = 'automobile/SET_AUTOMOBILE_TYPELIST';
export const SET_FAVORITE_AUTOMOBILE = 'automobile/SET_FAVORITE_AUTOMOBILE';
export const ADD_FAVORITE_AUTOMOBILE = 'automobile/ADD_FAVORITE_AUTOMOBILE';
export const REMOVE_FAVORITE_AUTOMOBILE = 'automobile/REMOVE_FAVORITE_AUTOMOBILE';
export const SET_SERVICE_BLOG_AUTOMOBILE = 'automobile/SET_SERVICE_BLOG_AUTOMOBILE';

// blog reducer
export const SET_BLOG_ITEMS = 'blog/SET_BLOG_ITEMS';
export const SET_BLOG_IS_LOADING = 'blog/SET_BLOG_IS_LOADING';
export const SET_BLOG_HAS_LOADED = 'blog/SET_BLOG_HAS_LOADED';
export const SET_BLOG_ITEM = 'blog/SET_BLOG_ITEM';
export const SET_BLOG_CATEGORIES = 'blog/SET_BLOG_CATEGORIES';

// product combo
export const SET_PRODUCT_COMBO = 'productcombo/SET_PRODUCT_COMBO';

// general pages
export const SET_PAGE_ERROR = 'page/SET_PAGE_ERROR';
export const SET_PAGE_ABOUT_US = 'page/SET_PAGE_ABOUT_US';
export const SET_PAGE_PRIVACY_POLICY = 'page/SET_PAGE_PRIVACY_POLICY';
export const SET_PAGE_REFUND_POLICY = 'page/SET_PAGE_REFUND_POLICY';
export const SET_PAGE_TERMS_CONDITIONS = 'page/SET_PAGE_TERMS_CONDITIONS';
export const SET_PAGE_FAQ = 'page/SET_PAGE_FAQ';
export const SET_PAGE_PRODUCT = 'page/SET_PAGE_PRODUCT';
export const SET_PAGE_AUTOMOBILE = 'page/SET_PAGE_AUTOMOBILE';
export const SET_PAGE_HOME = 'page/SET_PAGE_HOME';
export const SET_PAGE_HOMELOADING_TRUE = 'page/SET_PAGE_HOMELOADING_TRUE';
export const SET_PAGE_HOMELOADING_FALSE = 'page/SET_PAGE_HOMELOADING_FALSE';
export const SET_PAGE_CONTACT_US = 'page/SET_PAGE_CONTACT_US';
export const SET_PAGE_CAREER = 'page/SET_PAGE_CAREER';
export const SET_PAGE_CAREER_DETAIL = 'page/SET_PAGE_CAREER_DETAIL';
export const SET_PAGE_SELL_AT_UG = 'page/SET_PAGE_SELL_AT_UG';

// process cart reducer
export const SET_PROCESS_CART_DELIVERY_METHOD =
  'processCart/SET_PROCESS_CART_DELIVERY_METHOD';
export const SET_PROCESS_CART_PRODUCT_TYPE =
  'processCart/SET_PROCESS_CART_PRODUCT_TYPE';
export const SET_PROCESS_CART_STEP = 'processCart/SET_PROCESS_CART_STEP';
export const SET_PROCESS_CART_CUSTOMIZATION =
  'processCart/SET_PROCESS_CART_CUSTOMIZATION';
export const SET_PROCESS_CART_DELIVERY_DATE =
  'processCart/SET_PROCESS_CART_DELIVERY_DATE';
export const SET_PROCESS_CART_SHIPPING_ADDRESS =
  'processCart/SET_PROCESS_CART__SHIPPING_ADDRESS';
export const SET_PROCESS_CART_DELIVERY_TIME =
  'processCart/SET_PROCESS_CART_DELIVERY_TIME';
export const SET_PROCESS_CART_PRODUCT_QUANTITY =
  'processCart/SET_PROCESS_CART_PRODUCT_QUANTITY';
export const SET_PROCESS_CART_CURRENT_ORDER_ID =
  'processCart/SET_PROCESS_CART_CURRENT_ORDER_ID';
export const SET_PROCESS_CART_DELIVERY_LOCATION_ADDRESS =
  'processCart/SET_PROCESS_CART_DELIVERY_LOCATION_ADDRESS';
export const SET_PROCESS_CART_FIRST_DELIVERY_DATE =
  'processCart/SET_PROCESS_CART_FIRST_DELIVERY_DATE';
export const SET_PROCESS_CART_CAKE_PRICE =
  'processCart/SET_PROCESS_CART_CAKE_PRICE';
export const SET_PROCESS_CART_CURRENT_ADDRESS =
  'processCart/SET_PROCESS_CART_CURRENT_ADDRESS';

export const FETCH_CONTEST_INFO = 'contest/FETCH_CONTEST_INFO';
export const FETCH_CONTEST_INFO_SUCCESS = 'contest/FETCH_CONTEST_INFO_SUCCESS';
export const FETCH_CONTEST_INFO_FAIL = 'contest/FETCH_CONTEST_INFO_FAIL';

export const FETCH_MORE_CONTEST_LIST = 'contest/FETCH_MORE_CONTEST_LIST';
export const FETCH_MORE_CONTEST_LIST_SUCCESS =
  'contest/FETCH_MORE_CONTEST_LIST_SUCCESS';
export const FETCH_MORE_CONTEST_LIST_FAIL =
  'contest/FETCH_MORE_CONTEST_LIST_FAIL';

export const FETCH_CONTEST_LIST_BY_SLUG = 'contest/FETCH_CONTEST_LIST_BY_SLUG';
export const FETCH_CONTEST_LIST_BY_SLUG_SUCCESS =
  'contest/FETCH_CONTEST_LIST_BY_SLUG_SUCCESS';
export const FETCH_CONTEST_LIST_BY_SLUG_FAIL =
  'contest/FETCH_CONTEST_LIST_BY_SLUG_FAIL';

export const FETCH_CONTESTANT_DETAIL = 'contest/FETCH_CONTESTANT_DETAIL';
export const FETCH_CONTESTANT_DETAIL_SUCCESS =
  'contest/FETCH_CONTESTANT_DETAIL_SUCCESS';
export const FETCH_CONTESTANT_DETAIL_FAIL =
  'contest/FETCH_CONTESTANT_DETAIL_FAIL';

export const FETCH_NEXT_CONTESTANT_DETAIL =
  'contest/FETCH_NEXT_CONTESTANT_DETAIL';
export const FETCH_NEXT_CONTESTANT_DETAIL_SUCCESS =
  'contest/FETCH_NEXT_CONTESTANT_DETAIL_SUCCESS';
export const FETCH_NEXT_CONTESTANT_DETAIL_FAIL =
  'contest/FETCH_NEXT_CONTESTANT_DETAIL_FAIL';

export const FETCH_CONTEST_LIST = 'contest/FETCH_CONTEST_LIST';
export const FETCH_CONTEST_LIST_SUCCESS = 'contest/FETCH_CONTEST_LIST_SUCCESS';
export const FETCH_CONTEST_LIST_FAIL = 'contest/FETCH_CONTEST_LIST_FAIL';

export const ADD_VIEW_OR_LIKE = 'contest/ADD_VIEW_OR_LIKE';

export const SET_PAGE_FEEDBACK_DETAIL = 'page/SET_PAGE_FEEDBACK_DETAIL';
export const SET_PAGE_COMPLAIN_DETAIL = 'page/SET_PAGE_COMPLAIN_DETAIL';

// Quiz
export const SET_QUIZ_LIST = 'quiz/SET_QUIZ_LIST';
export const SET_QUIZ_DETAILS = 'quiz/SET_QUIZ_DETAILS';
export const SET_QUIZ_STATS = 'quiz/SET_QUIZ_STATS';
export const SET_QUIZ_LOADING = 'quiz/SET_QUIZ_LOADING';
export const SET_QUIZ_LOADED = 'quiz/SET_QUIZ_LOADED';

// Comment
export const SET_COMMENT_LIST = 'comment/SET_COMMENT_LIST';
export const SET_COMMENT_LOADING = 'comment/SET_COMMENT_LOADING';
export const SET_COMMENT_LOADED = 'comment/SET_COMMENT_LOADED';

// Store
export const SET_STORE_DETAILS = 'store/SET_STORE_DETAILS';
export const SET_STORE_LOADING = 'store/SET_STORE_LOADING';
export const SET_STORE_LOADED = 'store/SET_STORE_LOADED';

// Campaign
export const SET_CAMPAIGN_LOADING = 'campaign/SET_CAMPAIGN_LOADING';
export const SET_CAMPAIGN_LOADED = 'campaign/SET_CAMPAIGN_LOADED';
export const SET_CAMPAIGN_DETAILS = 'campaign/SET_CAMPAIGN_DETAILS';
export const SET_CAMPAIGN_OFFERS = 'campaign/SET_CAMPAIGN_OFFERS';
export const SET_CAMPAIGN_STORES = 'campaign/SET_CAMPAIGN_STORES';
export const SET_CAMPAIGN_PRODUCTS = 'campaign/SET_CAMPAIGN_PRODUCTS';
export const Add_CAMPAIGN_PRODUCTS = 'campaign/Add_CAMPAIGN_PRODUCTS';

// Roulette
export const SET_ROULETTE_DETAILS = 'roulette/SET_ROULETTE_DETAILS';
export const SET_ROULETTE_LOADING = 'roulette/SET_ROULETTE_LOADING';
export const SET_ROULETTE_LOADED = 'roulette/SET_ROULETTE_LOADED';