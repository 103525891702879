import {
  SET_PROCESS_CART_DELIVERY_METHOD,
  SET_PROCESS_CART_PRODUCT_TYPE,
  SET_PROCESS_CART_STEP,
  SET_PROCESS_CART_CUSTOMIZATION,
  SET_PROCESS_CART_DELIVERY_DATE,
  SET_PROCESS_CART_SHIPPING_ADDRESS,
  SET_PROCESS_CART_DELIVERY_TIME,
  SET_PROCESS_CART_PRODUCT_QUANTITY,
  SET_PROCESS_CART_CURRENT_ORDER_ID,
  SET_PROCESS_CART_DELIVERY_LOCATION_ADDRESS,
  SET_PROCESS_CART_FIRST_DELIVERY_DATE,
  SET_PROCESS_CART_CAKE_PRICE,
  SET_PROCESS_CART_CURRENT_ADDRESS,
} from '../types';
import {
  firstDeliveryDateLink,
  checkDeliveryLocationEligibilityLink,
} from '../../services/apiLink.js';

import { apiV2, authV2 } from '../../services/api';
import store from '../store';

export const setUserCurrentLocation = (data, cb = null) => dispatch => {
  dispatch({
    type: SET_PROCESS_CART_CURRENT_ADDRESS,
    payload: data,
  });
  if (cb) {
    cb(true, data);
  }
};

export const setUserNearestDeliveryLocation = ( data = null, cb = null ) => async dispatch => {
  if (!data) {
    data = store.getState().processCart.gps;
  }

  try {
    const nearestLocation = await apiV2.get(`location-by-gps?latitude=${data.lat}&longitude=${data.lng}`);
    const deliveryLocation = nearestLocation.data.data.id;
    dispatch({
        type: SET_PROCESS_CART_DELIVERY_LOCATION_ADDRESS,
        payload: deliveryLocation,
    });
    if( cb ) {
        cb( true, nearestLocation );
    }
  } catch (error) {
    if( cb ) {
        cb( false, error.response );
    }
  }
};

export const setSelectedDeliveryLocation = (data, cb = null) => dispatch => {
  dispatch({
    type: SET_PROCESS_CART_DELIVERY_LOCATION_ADDRESS,
    payload: data,
  });

  // one week interval till the location is remembered
  localStorage.setItem('ug_delivery_last_location', data);
  localStorage.setItem(
    'ug_delivery_last_location_expiry',
    new Date(new Date().getTime() + 72 * 24 * 60 * 60 * 1000).getTime()
  );

  if (cb) {
    cb(data);
  }
};

export const fetchFirstDeliveryDate = (cartItemsIds, cb = null) => async dispatch => {
  try {
    const deliveryLocationId = store.getState().processCart.shippingAddress.deliveryLocationId;
    
    let apiData = {
        cartItems: cartItemsIds,
        locationId: deliveryLocationId,
    }
    const res = await apiV2.post(firstDeliveryDateLink, apiData);
    if (res.data.data) {
      dispatch({
        type: SET_PROCESS_CART_FIRST_DELIVERY_DATE,
        payload: res.data.data.deliveryDate,
      });

      if (cb) {
        cb(true, res);
      }
    } else if (cb) {
      cb(false, res);
    }
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }
};

export const setCartProcessDeliveryMethod = (data, cb = null) => dispatch => {
  try {
    dispatch({
      type: SET_PROCESS_CART_DELIVERY_METHOD,
      payload: data,
    });

    if (cb) {
      cb(true);
    }
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }
};

export const setCartProcessDeliveryTime = (data, cb = null) => dispatch => {
  try {
    dispatch({
      type: SET_PROCESS_CART_DELIVERY_TIME,
      payload: data,
    });

    if (cb) {
      cb(true);
    }
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }
};

export const setCartProcessCartStep = (data, cb = null) => dispatch => {
  try {
    dispatch({
      type: SET_PROCESS_CART_STEP,
      payload: data,
    });

    if (cb) {
      cb(true);
    }
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }
};

export const setCartProcessShippingAddress = (data, cb = null) => dispatch => {
  try {
    dispatch({
      type: SET_PROCESS_CART_SHIPPING_ADDRESS,
      payload: data,
    });

    if (cb) {
      cb(true);
    }
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }
};

export const setCartProcessDeliveryDate = (data, cb = null) => dispatch => {
  try {
    dispatch({
      type: SET_PROCESS_CART_DELIVERY_DATE,
      payload: data,
    });

    if (cb) {
      cb(true);
    }
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }
};

export const setCartProcessCustomize = (data, cb = null) => dispatch => {
  try {
    dispatch({
      type: SET_PROCESS_CART_CUSTOMIZATION,
      payload: data,
    });

    if (cb) {
      cb(true);
    }
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }
};

export const setProcessCartProductType = (data, cb = null) => dispatch => {
  try {
    const type = data.cakeDetails ? 'cake' : 'product';
    dispatch({
      type: SET_PROCESS_CART_PRODUCT_TYPE,
      payload: type,
    });

    if (cb) {
      cb(true);
    }
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }
};

export const setProcessCartProductQuantity = (data, cb = null) => dispatch => {
  try {
    dispatch({
      type: SET_PROCESS_CART_PRODUCT_QUANTITY,
      payload: parseInt(data),
    });

    if (cb) {
      cb(true);
    }
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }
};

export const setCurrentOrderId = (data, cb) => dispatch => {
  const newData = data.map(item => item.orderId);
  dispatch({
    type: SET_PROCESS_CART_CURRENT_ORDER_ID,
    payload: newData,
  });
};

export const setCakePrice = (data, cb) => dispatch => {
  dispatch({
    type: SET_PROCESS_CART_CAKE_PRICE,
    payload: data,
  });
  if (cb) {
    cb(true, data);
  }
};

export const checkDeliveryLocationEligibility = ( address, cartItems , cb = null ) => async dispatch => {
    try {
        const res = await authV2.post(checkDeliveryLocationEligibilityLink, { locationId: address.deliveryLocationId, cartItems });
        address = {
            ...address, 
            deliveryDays: res.data.data.duration,
            deliveryCharge: res.data.data.charge,
            chargeInfo: res.data.data.chargeInfo,
            minMethodCharge: res.data.data.minMethodCharge,
        };
        dispatch({
            type: SET_PROCESS_CART_SHIPPING_ADDRESS,
            payload: address,
        });
        dispatch({
            type: SET_PROCESS_CART_FIRST_DELIVERY_DATE,
            payload: res.data.data.firstDeliveryDate,
        });
        if (cb) {
            cb(true, res);
        }
    } catch (error) {
        if (cb) {
            cb(false, error.response);
        }
    }
};
