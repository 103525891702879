/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Card, Button } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchErrorPage } from '../../redux/actions/pageActions';

const Error404 = props => {
  useEffect(() => {
    props.fetchErrorPage();
  });

  const { page } = props;
  return (
    <>
      <div
        className="error-page"
        style={{
          backgroundImage: `url(${
            page.banner !== undefined ? page.banner.backgroundImage : ''
          })`,
        }}
      >
        <Card>
          {page.banner !== undefined ? (
            <div className="big">
              <h1>{page.banner.title}</h1>
              <br />
              <br />
            </div>
          ) : (
            <>
              <div className="big">
                <h1 className="errorLetter">4</h1>{' '}
                <img src="/images/Search.png" alt="page not found" />
                <h1 className="errorLetter">4</h1>
              </div>
              <br />
              <br />
              <h1>Oops!</h1>
            </>
          )}
          <h2>
            {' '}
            {page.banner !== undefined
              ? page.banner.subtitle
              : 'The page you are looking for doesnt exist'}
            .{' '}
          </h2>

          {page.links == undefined ? (
            <Button>
              <Link to="/"> Home Page</Link>
            </Button>
          ) : (
            <div className='links' >
              {page.links.map((item, index ) => (
                <Link key={index} to={item.url} tag="div">
                  <div className='link-item' style={{ backgroundImage: `url(${item.image})` }} >
                    <div className='overlay'>
                      {item.title}
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          )}
        </Card>
      </div>
    </>
  );
};

export default connect(
  state => ({
    page: state.page.error,
  }),
  { fetchErrorPage }
)(Error404);
