import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Headroom from 'react-headroom';
import {
  Row,
  Menu,
  Button,
  Alert,
  Icon,
  Popover,
} from 'antd';
import { connect } from 'react-redux';
import { fetchFooterItems } from '../../redux/actions/footerActions';
import { logout } from '../../redux/actions/userActions';
import history from '../../router/history';
import MegaMenu from './megaMenu';
import { withRouter } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import StateLink from '../buttons/StateLink';
import { getFormattedPrice } from '../../services/commonFunc';

const { SubMenu } = Menu;
// #endregion

class PageHeader extends Component {
  constructor(props) {
    super(props);
    this.expandNav = this.expandNav.bind(this);
    this.state = {
      active: false,
      menu: {},
      logo: 'https://send-gifts-to-nepal.ugcakes.com/assets/logo/ug-cakes-web-logo-small.webp',
      type: '',
      currentLocation: '/',
    };
  }

  componentDidMount() {
    if ( ! this.props.loading && Object.keys(this.props.data).length == 0) {
      this.props.fetchFooterItems();
    }

    if( this.props.location ) {
      this.setState( { currentLocation: this.props.location.pathname } );
    }
  }

  componentWillReceiveProps( nextProps ) {
    if( nextProps.location.pathname != this.props.location.pathname ) {
      this.setState( { currentLocation: nextProps.location.pathname } );
    }
  }

  getNav(login) {
    const { compare, data } = this.props;
    
    let compareAutomobile = localStorage.getItem('automobileCompareIds') || [];
    if( ! Array.isArray( compareAutomobile ) ) {
        compareAutomobile = JSON.parse( compareAutomobile );
    }
    
    if (!login) {
      return (
          <Menu
          mode="horizontal"
          className="topMenu"
        >
            {/* <Menu.Item key="donations">
                <Link to={`/donations`} style={{textTransform: 'capitalize'}}>
                    Donate Now!
                </Link>
            </Menu.Item> */}
            <Menu.Item key="roulette">
                <Link to={`/roulette/test-your-luck`} style={{textTransform: 'capitalize'}}>
                    Play!
                </Link>
            </Menu.Item>
            { data.campaign &&
                <Menu.Item key='campaign'>
                    <Link to={`/campaign/${data.campaign.slug}`} style={{textTransform: 'capitalize'}}>
                        <img src={data.campaign.icon} height='40px' style={{paddingRight: '5px'}} alt={data.campaign.name} />
                    </Link>
                </Menu.Item>
            }
          {compare.length && (
            <Menu.Item key="compare-31">
              <Link to="/compare">
                Compare Products<sup>{compare.length}</sup>
              </Link>
            </Menu.Item>
          )}
          <Menu.Item key="35">
            <Link to="/compare/automobile">
                Compare<sup>{compareAutomobile.length || ''}</sup>
            </Link>
          </Menu.Item>
          <Menu.Item key="2">
            <StateLink to='/login'>Login</StateLink>
          </Menu.Item>
          <Menu.Item key="3">
            <Link to="/register">Register</Link>
          </Menu.Item>
        </Menu>
      );
    }
    let referrer = window.location.href;
    referrer = referrer.replace( window.location.origin, '' );

    return (
      <Menu
        mode="horizontal"
        className="topMenu"
      >
        {/* <Menu.Item key="donations">
            <Link to={`/donations`} style={{textTransform: 'capitalize'}}>
                Donate Now!
            </Link>
        </Menu.Item> */}
        <Menu.Item key="roulette">
            <Link to={`/roulette/latest`} style={{textTransform: 'capitalize'}}>
                Play!
            </Link>
        </Menu.Item>
        { data.campaign &&
            <Menu.Item key='campaign'>
                <Link to={`/campaign/${data.campaign.slug}`} style={{textTransform: 'capitalize'}}>
                    <img src={data.campaign.icon} height='40px' style={{paddingRight: '5px'}} alt={data.campaign.name}/>
                </Link>
            </Menu.Item>
        }
        {compare.length && (
          <Menu.Item key="34">
            <Link to="/compare">
              Compare Products<sup>{compare.length}</sup>
            </Link>
          </Menu.Item>
        )}
        <Menu.Item key="35">
            <Link to="/compare/automobile">
                Compare<sup>{compareAutomobile.length || ''}</sup>
            </Link>
        </Menu.Item>
        <Menu.Item key="4">
          <Link to="/profile">Profile</Link>
        </Menu.Item>
        <Menu.Item key="5">
          <Link
            to="#"
            onClick={() => {
              this.props.logout(() => {
                history.push({
                  pathname: '/login',
                  state: { referrer },
                });
              });
            }}
          >
            Logout
          </Link>
        </Menu.Item>
      </Menu>
    );
  }

  getList(list) {
    const { menu } = this.state;
    if (typeof menu[list] === 'object') {
      return (
        <SubMenu key={`sub-${list}`} title={list}>
          {Object.keys(menu[list]).map(data => (
            <Menu.Item key={`sub${data}`}>
              <Link to={menu[list][data]}> {data}</Link>
            </Menu.Item>
          ))}
        </SubMenu>
      );
    }
    return (
      <Menu.Item key={`menu---${menu[list]}---${list}`}>
        <NavLink activeClassName="active" to={`/${menu[list]}`}>
          {' '}
          {list}
        </NavLink>
      </Menu.Item>
    );
  }

  generateCartList() {
    const { cart } = this.props;
    return (
      <div
        className="head-cart"
        style={window.innerWidth <= 640 ? { display: 'none' } : {}}
      >
        {cart.map((item) => (
            <div className='head-cart-item' key={item.cartItemId}>
                <Link to={`/product/${item.slug}`}>
                    <div className='item-image' style={{backgroundImage: `url(${item.image})`}} />
                </Link>
                <div className='item-details'>
                    <Link to={`/product/${item.slug}`}><h4 className='title overflow-ellipse'>{item.name}</h4></Link>
                    <div className='info'>
                        <span className='price'>{ getFormattedPrice( ( item.sellingPrice || item.sale.price ) * ( item.quantity || 1 ) ) }</span>
                        <span className='quantity'>Quantity: {item.quantity || '1'}</span>
                    </div>
                </div>
                <Button onClick={() => this.removeFromCart(item)} type='link'><Icon type='delete'/></Button>
            </div>
        ))}
        {!!cart.length && (
            <div className='head-view-cart'>
                <Link to="/cart">
                    <Button>View Cart</Button>
                </Link>
            </div>
        )}
        {!cart.length && (
          <Alert
            style={{ margin: 0 }}
            message="No Items In the Cart"
            type="info"
          />
        )}
      </div>
    );
  }

  expandNav() {
    if( isMobileOnly ) {
      const currentState = this.state.active;
      this.setState({ active: !currentState });
    }
  }

  removeFromCart(item) {
    this.props.removeFromCart(item);
  }

  handleSelectChange = value => {
    this.setState({ type: value });
  };

  render(list) {
    const { cart: cartList } = this.props;
    const { menu, active, logo } = this.state;
    const { isLoggedIn, data } = this.props;

    return (
      <Headroom>
        <div className={active ? 'header active' : 'header'}>
          <div className="container">
            <div className="top-menu clearfix">{this.getNav(isLoggedIn)}</div>
            <div className="main-menu clearfix">
              <Row>
                <div className="left">
                  <Link to="/" className="logo">
                    <img src={logo} alt="" />
                  </Link>
                </div>
                <div className="menu-trigger">
                  <Button onClick={this.expandNav}>
                    {' '}
                    <Icon type={active ? 'close' : 'menu'} />{' '}
                  </Button>
                </div>
                <div className='right search-feild'>
                  <li className="cart-icon">
                    <Link to='/search'><Icon type={'search'} /></Link>
                  </li>

                  <li className="cart-icon">
                    <Popover placement='bottomRight' content={this.generateCartList()}>
                      <Link className="ant-dropdown-link" to="/cart">
                        <Icon type="shopping-cart" />
                        <div className="count">{cartList.length}</div>
                      </Link>
                    </Popover>
                  </li>
                </div>
                <div className="navigation">
                  <Menu
                    id="menu-nav"
                    style={{ width: 256 }}
                    mode="vertical"
                    className="left category-navigation"
                  >
                    <SubMenu
                      title={
                        <span>
                          <Icon type="appstore" />
                          <span>Shop By Categories</span>
                        </span>
                      }
                    >
                      <MegaMenu
                        items={
                          Object.keys(data).length > 0
                            ? data.categories.items
                            : []
                        }
                      />
                    </SubMenu>
                  </Menu>
                  {/* {JSON.stringify(data)} */}
                  <Menu
                    mode={active ? 'vertical' : 'horizontal'}
                    className="left mainMenu"
                    defaultSelectedKeys={['/']}
                    selectedKeys={[this.state.currentLocation]}
                  >
                    {Object.keys(data).length > 0 &&
                      data.topMenu.map((item, mainIndex) => {
                        if (item.items) {
                          return (
                            <SubMenu
                              key={mainIndex + item.title}
                              title={item.title}
                            >
                              {item.items.map((data, index) => (
                                <Menu.Item key={data.url}>
                                  <Link to={`${data.url}`} onClick={this.expandNav}> {data.title}</Link>
                                </Menu.Item>
                              ))}
                            </SubMenu>
                          );
                        }
                        return (
                          <Menu.Item key={item.url}>
                            {JSON.stringify(item.items)}
                            <NavLink
                              activeClassName="active"
                              to={`${item.url}`}
                              onClick={this.expandNav}
                            >
                              {' '}
                              {item.title}
                            </NavLink>
                          </Menu.Item>
                        );
                      })}
                  </Menu>
                </div>
              </Row>
            </div>
          </div>
        </div>
      </Headroom>
    );
  }
}

const mapStateToProps = state => ({
  isLoggedIn: state.user.isLoggedIn,
  compare: state.product.compare,
  loading: state.page.homeLoading,
  data: state.home.header,
});

export default connect(mapStateToProps, {
  logout,
  fetchFooterItems,
})(withRouter(PageHeader));
