import {
  SET_AUCTION_IS_LOADING,
  SET_AUCTION_HAS_LOADED,
  SET_AUCTION_ITEM,
  SET_AUCTION_ITEMS,
  SET_MY_AUCTION_ITEMS,
  SET_SELF_AUCTION_ITEM,
  SET_AUCTION_PRODUCT_SEARCH,
  SET_AUCTION_PRODUCT_SELECT,
  SET_AUCTION_ERROR,
  SET_MY_BID_FOR_AUCTION,
} from '../types';

const initState = {
  items: [],
  itemsPagination: {},
  loading: false,
};

const auctionReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_AUCTION_IS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case SET_AUCTION_HAS_LOADED:
      return {
        ...state,
        loading: false,
      };

    case SET_AUCTION_ITEMS:
      return {
        ...state,
        items: action.payload,
      };
    case SET_MY_AUCTION_ITEMS:
      return {
        ...state,
        items: action.payload,
      };
    case SET_MY_BID_FOR_AUCTION:
      return {
        ...state,
        items: action.payload,
      };
    case SET_AUCTION_ITEM:
      return {
        ...state,
        item: action.payload,
      };
    case SET_SELF_AUCTION_ITEM:
      return {
        ...state,
        itemDetail: action.payload,
      };
    case SET_AUCTION_PRODUCT_SEARCH:
      return {
        ...state,
        item: action.payload,
      };
    case SET_AUCTION_PRODUCT_SELECT:
      return {
        ...state,
        item: action.payload,
      };
    case SET_AUCTION_ERROR:
      return {
        ...state,
        item: action.payload,
      };
    default:
      return state;
  }
};
export default auctionReducer;
