import {
    SET_QUIZ_LIST,
    SET_QUIZ_DETAILS,
    SET_QUIZ_LOADING,
    SET_QUIZ_LOADED,
    SET_QUIZ_STATS,
} from '../types';

const initialState = {
    items: [],
    itemDetails: {},
    stats: [],
    loading: false,
}

const quizReducer = ( state = initialState, action ) => {
    switch( action.type ) {
        case SET_QUIZ_LIST:
            return {
                ...state,
                items: action.payload,
            };
        case SET_QUIZ_DETAILS:
            return {
                ...state,
                itemDetails: action.payload,
            }
        case SET_QUIZ_STATS:
            return {
                ...state,
                stats: action.payload,
            }
        case SET_QUIZ_LOADING:
            return {
                ...state,
                loading: true,
            }
        case SET_QUIZ_LOADED:
            return {
                ...state,
                loading: false,
            }
        default:
            return state;
    }
}

export default quizReducer;