/* eslint-disable react/prop-types */
/* eslint-disable react/no-deprecated */
import { hot } from 'react-hot-loader/root';
import React from 'react';
// import { Link } from "react-router-dom";
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import 'antd/dist/antd.css';
import 'react-owl-carousel2/src/owl.carousel.css';
import 'react-owl-carousel2/src/owl.theme.default.css';
import 'react-image-lightbox/style.css';
import './App.scss';
import {
  checkUserAuthStatus,
  fetchFollows,
  currencyConversion,
  fetchFavoriteNCart,
} from './redux/actions/userActions';
import {
  setSelectedDeliveryLocation,
} from './redux/actions/processCartActions';
import { setCompareProduct } from './redux/actions/productActions';
import PublicRouter from './router/index.js';
import history from './router/history';
import { message } from 'antd';

class App extends React.Component {
    constructor( props ) {
        super( props );
        
    }
  componentDidMount() {
    const {
      checkUserAuthStatus,
      setSelectedDeliveryLocation,
      fetchFavoriteNCart,
      fetchFollows,
      setCompareProduct,
      currencyConversion
    } = this.props;
    // Verifying User's token on application load
    (async () => {
        checkUserAuthStatus((status, data) => {
            if (status) {
                fetchFollows();
                fetchFavoriteNCart();
            } else {
                if( data && data.response && data.response.status === 401 ) {
                    let referrer = window.location.href;
                    referrer = referrer.replace( window.location.origin, '' );
                    localStorage.removeItem('ugcake_access_token');
                    history.push({
                        pathname: '/login',
                        state: { referrer },
                    });
                }
            }
        });
    })();
    (async () => {
      currencyConversion( (succ, err) => {
        if (err && err.data && err.data.data) {
          localStorage.setItem("currency_info", JSON.stringify(err.data.data))
        }
        else {
          localStorage.setItem("currency_info", {})
        }
      })
    })();

    const compare = localStorage.getItem('ug_product_compare_item');
    const delivery = localStorage.getItem('ug_delivery_last_location');
    const compareValidDate = localStorage.getItem('ug_product_compare_expiry');
    const compareValidDeliveryDate = localStorage.getItem(
      'ug_delivery_last_location_expiry'
    );
    const compareValidateDeliveryLocations = localStorage.getItem( 'ug_delivery_location_list_expiry' );

    if (
      compareValidDeliveryDate &&
      new Date(parseInt(compareValidDeliveryDate)) > new Date()
    ) {
      setSelectedDeliveryLocation(delivery);
    }

    if (compareValidDate && new Date(parseInt(compareValidDate)) > new Date()) {
      setCompareProduct(
        compare === undefined
          ? []
          : compare.split(',').map(item => parseInt(item))
      );
    } else {
      localStorage.removeItem('ug_product_compare_expiry');
      localStorage.removeItem('ug_product_compare_item');
    }

    if( compareValidateDeliveryLocations &&  new Date(parseInt(compareValidateDeliveryLocations)) < new Date()) {
      localStorage.removeItem('ug_delivery_location_list');
      localStorage.removeItem('ug_delivery_location_alias');
      localStorage.removeItem('ug_delivery_location_list_expiry');
    }
    
    message.config({
        top: 10,
    });

  }

  render() {
    try {
      return (
        <div className="App">
          <PublicRouter />
        </div>
      );
    } catch (error) {
      console.log('Error in App ', error);
    }
  }
}

export default withRouter(
  connect(
    null,
    {
      currencyConversion,
      checkUserAuthStatus,
      setCompareProduct,
      fetchFavoriteNCart,
      fetchFollows,
      setSelectedDeliveryLocation,
    }
  )(hot(App))
);
