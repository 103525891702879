import {
  FETCH_CONTEST_INFO,
  FETCH_CONTEST_INFO_SUCCESS,
  FETCH_CONTEST_INFO_FAIL,
  FETCH_MORE_CONTEST_LIST,
  FETCH_MORE_CONTEST_LIST_SUCCESS,
  FETCH_MORE_CONTEST_LIST_FAIL,
  FETCH_CONTEST_LIST_BY_SLUG,
  FETCH_CONTEST_LIST_BY_SLUG_SUCCESS,
  FETCH_CONTEST_LIST_BY_SLUG_FAIL,
  FETCH_CONTESTANT_DETAIL,
  FETCH_CONTESTANT_DETAIL_SUCCESS,
  FETCH_CONTESTANT_DETAIL_FAIL,
  FETCH_NEXT_CONTESTANT_DETAIL,
  FETCH_NEXT_CONTESTANT_DETAIL_SUCCESS,
  FETCH_NEXT_CONTESTANT_DETAIL_FAIL,
  FETCH_CONTEST_LIST,
  FETCH_CONTEST_LIST_SUCCESS,
  FETCH_CONTEST_LIST_FAIL,
  ADD_VIEW_OR_LIKE,
} from '../types';

const initialState = {
  contestants: [],
  contests: [],
  contestInfo: {},
  contestantDetailInfo: {},
  pagination: { lastPage: 2 },
  promotions: {},
  self: {},
  banner: undefined,
  loading: false,
  error: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONTEST_LIST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CONTEST_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        banner: action.contests.banner,
        contests: action.contests.contests,
      };
    case FETCH_CONTEST_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case FETCH_CONTEST_INFO:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CONTEST_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        banner: action.contests.banner,
        contests: action.contests.contests,
        contestants: action.contestants,
        contestInfo: action.contestInfo,
        promotions: action.promotions,
      };
    case FETCH_CONTEST_INFO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case FETCH_CONTEST_LIST_BY_SLUG:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CONTEST_LIST_BY_SLUG_SUCCESS:
      return {
        ...state,
        loading: false,
        contestInfo: action.contestInfo,
        promotions: action.promotions,
        self: action.self,
      };
    case FETCH_CONTEST_LIST_BY_SLUG_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case FETCH_MORE_CONTEST_LIST:
      return {
        ...state,
        loading: true,
        contestants: action.page === 1 ? [] : state.contestants,
      };
    case FETCH_MORE_CONTEST_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        contestants: [...state.contestants, ...action.contestants],
        pagination: action.pagination,
      };
    case FETCH_MORE_CONTEST_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case FETCH_CONTESTANT_DETAIL:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CONTESTANT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        contestInfo: action.contestInfo,
        contestantDetailInfo: action.contestantDetailInfo,
        promotions: action.promotions,
        self: action.self,
      };
    case FETCH_CONTESTANT_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case FETCH_NEXT_CONTESTANT_DETAIL:
      return {
        ...state,
        loading: true,
      };
    case FETCH_NEXT_CONTESTANT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        contestantDetailInfo: action.contestantDetailInfo,
      };
    case FETCH_NEXT_CONTESTANT_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ADD_VIEW_OR_LIKE:
      return {
        ...state,
        contestantDetailInfo: {
          ...state.contestantDetailInfo,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
