import {
  ADD_FILTER_SIZE,
  ADD_FILTER_COLOR,
  ADD_FILTER_BRAND,
  CLEAR_FILTERS,
  CHANGE_FILTER_MAX_PRICE,
  CHANGE_FILTER_MIN_PRICE,
  SET_FILTER_FILTER_BOX
} from "./../types";


const initState = {
  size: [],
  brand: [],
  color: [],
  tags: [],
  minPrice: 0,
  maxPrice: 5000,
  loading: false,
  filterBox: []
};

const filterReducer = (state = initState, action) => {
  switch (action.type) {
    case ADD_FILTER_SIZE:
      return {
        ...state,
        size: [...state.size, action.payload]
      };
    case SET_FILTER_FILTER_BOX:
      return {
        ...state,
        filterBox: action.payload
      };

    case CLEAR_FILTERS:
      return {
        ...state,
        ...initState
      };

    case ADD_FILTER_BRAND:
      return {
        ...state,
        brand: action.payload
      };


    case ADD_FILTER_COLOR:
      return {
        ...state,
        color: action.payload
      };

    case CHANGE_FILTER_MIN_PRICE:
      return {
        ...state,
        minPrice: action.payload
      };

    case CHANGE_FILTER_MAX_PRICE:
      return {
        ...state,
        maxPrice: action.payload
      };

    default:
      return state;

  }

};
export default filterReducer;