/* eslint-disable import/prefer-default-export */
import {
  SET_FOOTER_ITEMS,
  FOOTER_IS_LOADING,
  FOOTER_HAS_LOADED,
  SET_CATEGORY_ITEMS,
  SET_HOME_HEADER,
} from '../types';

import { apiV2 } from '../../services/api';

function checkCacheFooterExpiryTime() {
  if (
    localStorage.getItem('ugcake_headerfooter_expirytime') == null ||
    localStorage.getItem('ugcake_headerfooter_expirytime') == undefined ||
    localStorage.getItem('ugcake_headerfooter_expirytime') <
      new Date().valueOf()
  ) {
    localStorage.removeItem('ugcake_headerfooter');
  }
}

export const fetchFooterItems = (cb = null) => async dispatch => {
  try {
    dispatch({
      type: FOOTER_IS_LOADING,
    });
    
    checkCacheFooterExpiryTime();

    let res = '';
    const footer = localStorage.getItem('ugcake_headerfooter');
    const noFooterData = !footer || footer === 'undefined' || footer === null || footer === undefined;
    res = { data: { data: 'hasData' } };

    if ( noFooterData ) {
      res = await apiV2.get('header-footer');
    }

    const apiData = noFooterData ? res.data.data : JSON.parse(footer);

    if (cb) {
        cb(true, res);
    }
    if ( noFooterData ) {
        localStorage.setItem('ugcake_headerfooter', JSON.stringify(apiData));
        
        localStorage.setItem(
            'ugcake_headerfooter_expirytime',
            new Date().valueOf() + 1000 * 60 * 60 * 12,
        );
    }

    dispatch({
        type: SET_FOOTER_ITEMS,
        payload: apiData.footer,
    });
    dispatch({
        type: SET_CATEGORY_ITEMS,
        payload: apiData.header.categories.items,
    });
    dispatch({
        type: SET_HOME_HEADER,
        payload: apiData.header,
    });
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }

  dispatch({
    type: FOOTER_HAS_LOADED,
  });
};
