import {
    SET_STORE_DETAILS,
    SET_STORE_LOADING,
    SET_STORE_LOADED,
} from '../types';

const initialState = {
    loading: false,
    details: null,
}

const storeReducer = ( state = initialState, action ) => {
    switch( action.type ) {
        case SET_STORE_DETAILS:
            return {
                ...state,
                details: action.payload,
            };
        case SET_STORE_LOADING:
            return {
                ...state,
                loading: true,
            }
        case SET_STORE_LOADED:
            return {
                ...state,
                loading: false,
            }
        default:
            return state;
    }
}
export default storeReducer;