import React, { useState, useEffect } from 'react';
import { RuIcon } from '../components/IconPack';
import countryCodes from '../components/forms/CountryCodes.json';

export const getPriceFromUserIp = ( price, unit = false ) => {
  const currency_info = localStorage.getItem('currency_info');
  if (currency_info) {
    const { factor, round } = JSON.parse(currency_info);
    if( unit ) {
        return convertToUnit( (parseFloat(price) * factor).toFixed(round) );
    }
    return (parseFloat(price) * factor).toFixed(round) || 0;
  }
  if( unit ) {
      return convertToUnit( parseFloat( price ) );
  }
  return parseFloat(price) || 0;
};

export const convertToUnit = (price) => {
    if( price > 10000000 ) {
        return ( price / 10000000 ).toFixed(2) + ' crore';
    } else if( price > 100000 ) {
        return ( price / 100000 ).toFixed(2) + ' lakh';
    } else {
        return price;
    }
}

export const getCurrencyFromUserIp = () => {
  const currency_info = localStorage.getItem('currency_info');
  if (currency_info) {
    const { currency } = JSON.parse(currency_info);
    return currency === 'NPR' ? <RuIcon className="rupees" /> : currency;
  }
  return <RuIcon className="rupees" />;
};

export const getFormattedPrice = ( price, unit = false ) => {
  return (
    <>
      {getCurrencyFromUserIp()} {getPriceFromUserIp(price, unit)}
    </>
  );
};

// TODO: This is a redundant function for price filter only as it cannot work with Icon unless the filter is fixed
export const getCurrencyFromUserIpWithoutIcon = () => {
    const currency_info = localStorage.getItem('currency_info');
    if (currency_info) {
      const { currency } = JSON.parse(currency_info);
      return currency;
    }
    return 'NPR';
  };
  
// TODO: This is a redundant function for price filter only as it cannot work with Icon unless the filter is fixed
  export const getFormattedPriceWithoutIcon = price => {
    return getCurrencyFromUserIpWithoutIcon() + ' ' + getPriceFromUserIp(price);
  };

export const shortenLargeNumber = n => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};

export const FormattedDateTime = ({ children, prefix, suffix }) => {
  const [formattedDate, setFormattedDate] = useState();

  useEffect(() => {
    setFormattedDate(children);
    let interval = null;
    if (format(children)) {
      interval = setInterval(() => format(children), 1000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    }
  }, [children]);

  const format = ( date ) => {
    const now = parseInt( Date.now() / 1000 );
    const nextDate = new Date(date);
    const nextTime = parseInt( nextDate.getTime() / 1000 );

    const secInMinute = 60;
    const secInHours = secInMinute * 60;
    const secInDays = secInHours * 24;
    const secInMonths = secInDays * 30;

    if( nextTime > now ) {
      const diff = ( nextTime - now );
      const months = parseInt( diff / secInMonths );
      const days = parseInt( ( diff - ( months * secInMonths ) ) / secInDays );
      const hours = parseInt( ( diff - ( months * secInMonths ) - ( days * secInDays ) ) / secInHours );
      const minute = parseInt( ( diff - ( months * secInMonths ) - ( days * secInDays ) - ( hours * secInHours ) ) / secInMinute );
      const seconds = parseInt( ( diff - ( months * secInMonths ) - ( days * secInDays ) - ( hours * secInHours ) - ( minute * secInMinute ) ) );

      let newFormatedDate = (prefix) ? prefix : '';
      let count = 0;
      if( months > 0 ) {
        newFormatedDate += months + ( ( months > 1 ) ? ' months ' : ' month ' );
        count ++;
      }
      if( days > 0 ) {
        newFormatedDate += days + ( ( days > 1 ) ? ' days ' : ' day ' );
        count ++;
      }
      if( hours > 0 ) {
        newFormatedDate += hours + ( ( hours > 1 ) ? ' hrs ' : ' hr ' );
        count ++;
      }
      if( minute > 0 && count < 3 ) {
        newFormatedDate += minute + ( ( minute > 1 ) ? ' mins ' : ' min ' );
        count ++;
      }
      if( seconds > 0 && count < 3 ) {
        newFormatedDate += seconds + ( ( seconds > 1 ) ? ' secs' : ' sec' );
        count ++;
      }
      newFormatedDate += ( suffix ) ? suffix : '';
      setFormattedDate( newFormatedDate );
    } else {
      setFormattedDate( children );
      return false;
    }
    return true;
  }

  return (
    <span className="formatted-date">{formattedDate}</span>
  )
}

export const cacheImages = images => {
  let image;
  images.map( ( img ) => {
    image = new Image();
    image.src = img;
  } );
}

export const getLinkFromObject = ( obj ) => {
    if( obj.type && obj.slug ) {
        switch( obj.type ) {
            case 'product':
            case 'cake':
                return `/product/${obj.slug}`;
            // To-do: add casses.
        }

    }
    return '/';
}

export const stripHtml = (html) => {
    let temporalDivElement = document.createElement("div");
    temporalDivElement.innerHTML = html;
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
}

export const addItemHistory = ( data, type = 'product', options = {} ) => {
    const { unique = false, limit = 250 } = options;
    const key = `ugcake_history_${type}`;
    let log = localStorage.getItem( key );
    log = log ? JSON.parse( log ) : [];
    if( ! unique || log.indexOf( data ) === -1 ) {
        log.unshift( data );
    }
    if( log.length > limit ) {
        log.pop();
    }
    localStorage.setItem( key, JSON.stringify( log ) );
}

export const getItemHistory = ( type = 'product') => {
    const key = `ugcake_history_${type}`;
    let log = localStorage.getItem( key );
    return log ? JSON.parse( log ) : [];
}

export const getRegionCode = ( dial_code ) => {
    const country = countryCodes.find( country => country.dial_code === dial_code );
    if( country ) {
        return country.code;
    }
    return '';
}

export const isTesting = () => {
    if( 'www.ugbazaar.com' === window.location.hostname || 'www.ugcakes.com' === window.location.hostname) {
        return false;
    }
    return true;
}

export const isLocal = () => {
    return ('localhost' === window.location.hostname);
}

export const loadScript = (id, jsSrc, cb) => {
    const element = document.getElementsByTagName('script')[0]
    const fjs = element
    let js = element
    js = document.createElement('script')
    js.id = id
    js.src = jsSrc
    if (fjs && fjs.parentNode) {
        fjs.parentNode.insertBefore(js, fjs)
    } else {
        document.head.appendChild(js)
    }
    js.onload = cb
}

/*
'large' => '1080',
'640px' => '640',
'medium' => '480',
'360px' => '360',
'small' => '240',
'120px' => '120',
'extra-small' => '60',
*/
export const formatImage = ( image, size = null ) => {
    let img = image;
    if( typeof image == 'object' ) {
        img = image.path;
        if( size && image.file.match( /jpg|jpeg|png/g ) ) {
            img += size + '/';
        }
        img += image.file;
    }
    return img;
}