import axios from 'axios';
import { businessBaseURL, newBaseURL, newBaseURLV3 } from './apiLink';

export const apiV2 = axios.create({
  baseURL: newBaseURL,
  timeout: 60000,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'X-Content-Type-Options': 'nosniff'
  },
  crossDomain: true,
});

export const authV2 = axios.create({
  baseURL: newBaseURL,
  timeout: 60000,
  crossDomain: true,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'X-Content-Type-Options': 'nosniff'
  },
});


export const authBusinessV1 = axios.create({
    baseURL: businessBaseURL,
    timeout: 60000,
    crossDomain: true,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'X-Content-Type-Options': 'nosniff'
    },
});


authV2.interceptors.request.use(
  function(config) {
    const token = localStorage.getItem('ugcake_access_token');
    if( token ) {
      config.headers.common.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

apiV2.interceptors.response.use(
  function(response) {
    if (response.data.error && response.data.error.code == 500) {
      // Handle common error and others that might arise in the application
    }

    return response;
  },
  function(error) {
    if (
      error.config.hasOwnProperty('errorHandle') &&
      error.config.errorHandle === true
    ) {
      return Promise.reject(error);
    }
    throw error;
  }
);

authBusinessV1.interceptors.request.use(
    function(config) {
      const token = localStorage.getItem('ugcake_access_token');
      if( token ) {
        config.headers.common.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    function(error) {
      return Promise.reject(error);
    }
);