import {
    createStore,
    applyMiddleware,
    compose
} from "redux";
import thunk from "redux-thunk";
import rootReducer from './combineReducer.js';

const devToolState = () => {
    if (process.env.NODE_ENV === "development") {
        const composeEnhancers =
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
        return composeEnhancers(applyMiddleware(thunk));
    }
    return applyMiddleware(thunk);
};



const store = createStore(rootReducer, devToolState());
export default store;