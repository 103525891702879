import React from 'react';
import { Icon } from 'antd';

const ContestSvg = () => (
    <svg width="1em" height="1em" fill='currentColor' viewBox="0 0 65 62.779">
        <g id="Contest_Icon" data-name="Contest Icon" transform="translate(-391 -178.03)">
            <path id="Path_169" data-name="Path 169" d="M453.154,181.419H442.137c-.086-1.249-.162-2.306-.238-3.389H405.375v3.4c-4.052,0-7.893-.01-11.738.007a2.535,2.535,0,0,0-2.623,2.53c.635,4.96,1.3,9.933,3.614,14.465,1.788,3.5,4.4,6.206,8.491,6.513a5.437,5.437,0,0,1,2.388.673l4.822,1.657-.124.217c1.35,1,2.671,2.043,4.087,2.934,2.116,1.332,4.294,2.53,3.134,5.729H429.6c0-.853-.052-1.546.017-2.23.055-.532.09-1.253.431-1.536,2.35-1.947,4.777-3.8,7.258-5.743l5.2-1.529.055.1c5.284-.262,8.718-3.251,10.4-7.987a91.093,91.093,0,0,0,2.961-12.418A2.728,2.728,0,0,0,453.154,181.419Zm-54.733,16.767c-2.758-4-3.407-8.66-4.055-13.571,2.882,0,5.4-.041,7.921.017l3.648,7.076c.414,3.324,1.26,6.6,1.929,9.933C404.015,202.528,400.73,201.538,398.421,198.186Zm11.673,8.346c.007-.072.069-.169.235-.293C410.249,206.335,410.173,206.435,410.093,206.532Zm8.608,1.457a14.25,14.25,0,0,1-5.388-7.186c-2.288-6.182-2.678-12.639-2.716-19.363h4.19c.576,9.091-.083,18.427,6.068,26.262C420.337,208.675,419.616,208.6,418.7,207.989Zm29.241-8.87a7.852,7.852,0,0,1-8.27,2.578c.711-4.394,1.312-8.684,2.178-12.922l4.635-4.156h6.226C452.06,189.875,451.4,194.966,447.942,199.118Z" />
            <path id="Path_170" data-name="Path 170" d="M437.486,317.64v-5.988c6.977-.113,9.1-5.329,11.166-10.467a43.175,43.175,0,0,0,1.48-5.829h7.544c.968,2.97,1.753,5.9,2.9,8.688a18.063,18.063,0,0,0,2.916,4.422c1.78,2.155,3.915,3.748,7.087,2.87v6.3Z" transform="translate(-30.442 -76.831)" />
        </g>
    </svg>
)



const ContestIcon = props => <Icon component={ContestSvg} {...props} />
export default ContestIcon;