import { 
    SET_ROULETTE_DETAILS,
    SET_ROULETTE_LOADING, 
    SET_ROULETTE_LOADED,
} from "../types";

const initState = {
    loading: false,
    details: {},
}

const rouletteReducer = ( state = initState, action ) => {
    switch( action.type ) {
        case SET_ROULETTE_LOADING:
            return {
                ...state,
                loading: true,
            }
        case SET_ROULETTE_LOADED:
            return {
                ...state,
                loading: false,
            }
        case SET_ROULETTE_DETAILS:
            return {
                ...state,
                details: action.payload || {},
            }
        default:
            return state;
    }
}

export default rouletteReducer;