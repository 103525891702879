import {
    SET_COMMENT_LIST,
    SET_COMMENT_LOADING,
    SET_COMMENT_LOADED,
} from '../types';

const initialState = {
    loading: true,
    items: [],
    pagination: null,
};

const commentReducer = ( state = initialState, action ) => {
    switch( action.type ) {
        case SET_COMMENT_LOADING:
            return {
                ...state,
                loading: true,
            }
        case SET_COMMENT_LOADED:
            return {
                ...state,
                loading: false,
            }
        case SET_COMMENT_LIST:
            return {
                ...state,
                items: action.page > 1 ? [ ...state.items, ...action.payload ] : action.payload,
                pagination: action.pagination,
            }
        default:
            return state;
    }
}

export default commentReducer;