import {
    SET_OCCASION_ITEMS,
    SET_OCCASION_IS_LOADING,
    SET_OCCASION_HAS_LOADED
} from '../types';

const initState = {
    items: [],
    loading: false,
};

const occasionReducer = ( state = initState, action ) => {
    switch( action.type ) {
        case SET_OCCASION_ITEMS:
            return {
                ...state,
                items: action.payload
            }
        case SET_OCCASION_IS_LOADING:
            return {
                ...state,
                loading: true
            }
        case SET_OCCASION_HAS_LOADED:
            return {
                ...state,
                loading: false
            }
        default:
            return state;
    }
};

export default occasionReducer;