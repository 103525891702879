import {
  SET_PAGE_ABOUT_US,
  SET_PAGE_PRIVACY_POLICY,
  SET_PAGE_REFUND_POLICY,
  SET_PAGE_TERMS_CONDITIONS,
  SET_PAGE_FAQ,
  SET_PAGE_HOME,
  SET_PAGE_AUTOMOBILE,
  SET_CATEGORY_ITEMS,
  SET_PAGE_HOMELOADING_TRUE,
  SET_PAGE_HOMELOADING_FALSE,
  SET_CATEGORY_IS_LOADING,
  SET_CATEGORY_HAS_LOADED,
  SET_PAGE_ERROR,
  SET_PAGE_CONTACT_US,
  SET_PAGE_CAREER,
  SET_PAGE_CAREER_DETAIL,
  SET_FILTER_FILTER_BOX,
  SET_PRODUCT_ITEMS,
  ADD_PRODUCT_ITEMS,
  SET_PAGE_SELL_AT_UG,
} from '../types';
import {
  getAboutUsPageLink,
  getFaqPageLink,
  getTermsAndConditionPageLink,
  getPrivacyPolicyPageLink,
  getProductPageLink,
  getAutomobilePageLink,
  postContactUsLink,
  newletterSubscribeLink,
  getHomePageLink,
  getContactUsPageLink,
  getCareerPageLink,
  getCareerDetailPageLink,
  postCareerLink,
  getRefundPolicyPageLink,
  getSellAtUGPageLink
} from '../../services/apiLink.js';
import { apiV2, authV2 } from '../../services/api';
import { setFilterBox } from './filterActions';
import store from '../store';

export const fetchErrorPage = (cb = null) => async dispatch => {
  try {
    const { error } = store.getState().page;
    if (error.banner) {
      return;
    }

    const res = await apiV2.get('error-page');
    if (res.data.data) {
      if (cb) {
        cb(true, res);
      }

      dispatch({
        type: SET_PAGE_ERROR,
        payload: res.data.data,
      });
    } else {
      throw new Error('error');
    }
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }
  dispatch({
    type: SET_PAGE_HOMELOADING_FALSE,
  });
  dispatch({
    type: SET_CATEGORY_HAS_LOADED,
  });
};

export const fetchHomePage = (cb = null) => async dispatch => {
  try {
    dispatch({
      type: SET_CATEGORY_IS_LOADING,
    });

    dispatch({
      type: SET_PAGE_HOMELOADING_TRUE,
    });
    const res = await authV2.get(getHomePageLink);

    if (cb) {
      cb(true, res);
    }

    dispatch({
      type: SET_PAGE_HOME,
      payload: res.data.data,
    });
    dispatch({
      type: SET_CATEGORY_ITEMS,
      payload: res.data.data.header.categories.items,
    });
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }
  dispatch({
    type: SET_PAGE_HOMELOADING_FALSE,
  });
  dispatch({
    type: SET_CATEGORY_HAS_LOADED,
  });
};

export const fetchContactUsPage = (cb = null) => async dispatch => {
  try {
    const res = await apiV2.get(getContactUsPageLink);

    if (res.data.data) {
      dispatch({
        type: SET_PAGE_CONTACT_US,
        payload: res.data.data,
      });
      if (cb) {
        cb(true, res);
      }
    } else if (cb) {
      cb(false, res);
    }
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }
};

export const fetchCareerPage = (cb = null) => async dispatch => {
  try {
    const res = await apiV2.get(getCareerPageLink);

    if (res.data.data) {
      dispatch({
        type: SET_PAGE_CAREER,
        payload: res.data.data,
      });
      if (cb) {
        cb(true, res);
      }
    } else if (cb) {
      cb(false, res);
    }
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }
};

export const fetchCareerDetailPage = (id, cb = null) => async dispatch => {
  try {
    const res = await apiV2.get(`${getCareerDetailPageLink + id}`);

    if (res.data.data) {
      dispatch({
        type: SET_PAGE_CAREER_DETAIL,
        payload: res.data.data,
      });
      if (cb) {
        cb(true, res);
      }
    } else if (cb) {
      cb(false, res);
    }
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }
};

export const fetchAboutUsPage = (cb = null) => async dispatch => {
  try {
    const res = await apiV2.get(getAboutUsPageLink);

    if (res.data.data) {
      dispatch({
        type: SET_PAGE_ABOUT_US,
        payload: res.data.data,
      });
      if (cb) {
        cb(true, res);
      }
    } else if (cb) {
      cb(false, res);
    }
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }
};

export const fetchPrivacyPolicyPage = (cb = null) => async dispatch => {
  try {
    const res = await apiV2.get(getPrivacyPolicyPageLink);

    if (cb) {
      cb(true, res);
    }

    dispatch({
      type: SET_PAGE_PRIVACY_POLICY,
      payload: res.data.data,
    });
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }
};

export const fetchRefundPolicyPage = (cb = null) => async dispatch => {
    try {
      const res = await apiV2.get(getRefundPolicyPageLink);
  
      if (cb) {
        cb(true, res);
      }
  
      dispatch({
        type: SET_PAGE_REFUND_POLICY,
        payload: res.data.data,
      });
    } catch (error) {
      if (cb) {
        cb(false, error);
      }
    }
  };

export const fetchTermsAndConditionPage = (cb = null) => async dispatch => {
  try {
    const res = await apiV2.get(getTermsAndConditionPageLink);

    if (cb) {
      cb(true, res);
    }

    dispatch({
      type: SET_PAGE_TERMS_CONDITIONS,
      payload: res.data.data,
    });
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }
};

export const fetchFaqPage = (cb = null) => async dispatch => {
  try {
    const res = await apiV2.get(getFaqPageLink);

    if (cb) {
      cb(true, res);
    }

    dispatch({
      type: SET_PAGE_FAQ,
      payload: res.data.data,
    });
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }
};

export const fetchProductPage = ( queries, cb = null, loadMore = false) => async dispatch => {
  try {
    const res = await apiV2.get(getProductPageLink + queries);

    if (res.data.data) {
      dispatch({
        type: loadMore ? ADD_PRODUCT_ITEMS : SET_PRODUCT_ITEMS,
        payload: res.data.data.products,
      });
      dispatch({
        type: SET_FILTER_FILTER_BOX,
        payload: res.data.data.filterbox,
      });
      if (cb) {
        cb(true, res);
      }
    } else if (cb) {
      cb(false, res);
    }
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }
};

export const fetchAutomobilePage = (cb = null) => async dispatch => {
  try {
    const res = await apiV2.get(getAutomobilePageLink);
    dispatch({
      type: SET_PAGE_AUTOMOBILE,
      payload: res.data.data,
    });

    if (cb) {
      cb(true, res);
    }
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }
};

export const contactUs = (data, cb = null) => async dispatch => {
  try {
    const res = await authV2.post(postContactUsLink, data);
    if (cb) {
        cb(true, res);
    }
  } catch (error) {
    if (cb) {
      cb(false, error.response);
    }
  }
};

export const career = (data, cb = null) => async dispatch => {
  try {
    const res = await authV2.post(postCareerLink, data);
    if (res.data.status == 'success') {
      if (cb) {
        cb(true, res);
      }
    } else {
      if (cb) {
        cb(false, res);
      }
    }
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }
};

export const subscribeToNewsletter = (data, cb = null) => async dispatch => {
    try {
        const res = await apiV2.post(newletterSubscribeLink, data);
        if (cb) {
            cb(true, res);
        }
    } catch (error) {
        if (cb) {
            cb(false, error);
        }
    }
};

export const fetchSellAtUGPage = (cb = null) => async dispatch => {
    try {
      const res = await apiV2.get(getSellAtUGPageLink);
      dispatch({
        type: SET_PAGE_SELL_AT_UG,
        payload: res.data,
      });
  
      if (cb) {
        cb(true, res);
      }
    } catch (error) {
      if (cb) {
        cb(false, error);
      }
    }
  };