import UGContext from './UGContext';
import UGProvider from './UGProvider';
import withUGConfig from './withUGConfig';

var firebase_config = {
    apiKey: "AIzaSyDFSGB1z8jwnnlsHDzikKPZPMhxBWC_o7s",
    authDomain: "ugbazaar-d747e.firebaseapp.com",
    databaseURL: "https://ugbazaar-d747e.firebaseio.com",
    projectId: "ugbazaar-d747e",
    storageBucket: "ugbazaar-d747e.appspot.com",
    messagingSenderId: "125981109154",
    appId: "1:125981109154:web:14f8a52dea9aca08eba0fb",
    measurementId: "G-T51DQNNZ0R"
};

const config = {
    fb_api_id: '195801243473799',
    fb_version: 'v17.0',
    google_client_id: '634549848567-dl9si4vlel7gk0hq81m45vp0nlbfkt2n.apps.googleusercontent.com',
    quiz_const: '1586077474', // Timestamp.
    google_recaptcha_v3: '6Le0zrkUAAAAADPoe4v7k9-Ln5FDEEGv1jExAUrl',
    tawk_property_id: '5f290c809b7cf26e73c1ec25',
    firebase_config,
}

export default config;
export {
    UGContext,
    UGProvider,
    withUGConfig,
}