import { 
    SET_CAMPAIGN_DETAILS, 
    SET_CAMPAIGN_LOADING,
    SET_CAMPAIGN_LOADED, 
    SET_CAMPAIGN_OFFERS, 
    SET_CAMPAIGN_STORES, 
    SET_CAMPAIGN_PRODUCTS,
    Add_CAMPAIGN_PRODUCTS,
} from "../types";

const initState = {
    loading: false,
    details: {
        campaign: {},
        flashSale: [],
        sliders: [],
        featureDeals: [],
        categories: [],
        brands: [],
    },
    offers: [],
    stores: [],
    products: [],
}

const campaignReducer = ( state = initState, action ) => {
    switch( action.type ) {
        case SET_CAMPAIGN_LOADING:
            return {
                ...state,
                loading: true,
            }
        case SET_CAMPAIGN_LOADED:
            return {
                ...state,
                loading: false,
            }
        case SET_CAMPAIGN_DETAILS:
            return {
                ...state,
                details: action.payload || {},
            }
        case SET_CAMPAIGN_OFFERS:
            return {
                ...state,
                offers: action.payload || [],
            }
        case SET_CAMPAIGN_STORES:
            return {
                ...state,
                stores: action.payload || [],
            }
        case SET_CAMPAIGN_PRODUCTS:
            return {
                ...state,
                products: action.payload || [],
            }
        case Add_CAMPAIGN_PRODUCTS:
            return {
                ...state,
                products: [ ...state.products, ...action.payload || [] ],
            }
        default:
            return state;
    }
}

export default campaignReducer;