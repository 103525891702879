import React, { Fragment } from "react";
import { Spin } from "antd";

const flexCenter = {
    display: "flex",
    flexDirection: "column",
    minWidth: "100%",
    minHeight: "100vh",
    position: "fixed",
    textAlign: "center",
    justifyContent: "center"
};

const LoadingWrapper = props => (
    <Fragment>
        <div style={flexCenter}>
            <Spin>
                <div className="containers">
                    <h2>UG Cakes</h2>
                    <div>{props.children}</div>
                </div>
            </Spin>
        </div>
    </Fragment>
);

export default LoadingWrapper;
