/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import React, { Suspense } from 'react';
import { Router, Redirect } from 'react-router';
import { Switch } from 'react-router-dom';
import history from './history';
import { PublicRoute, ErrorRoute, PrivateRoute } from './routes';
import AppLoadingLayout from '../containers/wrappers/loading';

import WindowScroller from '../components/windowScrollTop';
// ERRORS
import Error404 from '../containers/errors/404';

const Homepage = React.lazy(() => import('../containers/homepage'));
const CategoriesPage = React.lazy(() => import('../containers/CategoriesPage'));

// PRODUCTS
const SearchPage = React.lazy(() => import('../containers/pages/searchPage'));
const ProductPage = React.lazy(() => import('../containers/pages/productPage'));
const ProductSingle = React.lazy(() => import('../containers/products/single'));
const ProductCollection = React.lazy(() => import('../containers/products/ProductCollection'));
const Donations = React.lazy(() => import('../containers/pages/donations'));
const Roulette = React.lazy(() => import('../containers/roulette/containers/roulette'));
const UrgentProductPage = React.lazy(() => import('../containers/products/urgent'));
const CakeFinder = React.lazy(() => import('../containers/pages/cakeFinder'));
const SpecialOccasion = React.lazy(() => import('../containers/pages/specialOccasion'));

const AutomobilesLanding = React.lazy(() => import('../containers/AutomobilePage'));
const AutomobileSingle = React.lazy(() => import('../containers/products/automobileSingle'));
const CompareProducts = React.lazy(() => import('../containers/products/CompareProducts'));
const AutomobilesBrandAndTypePage = React.lazy(() => import('../containers/products/automobileBrandAndTypePage'));
const AutomobileFinderPage = React.lazy(() => import('../containers/products/AutomobileFinderPage'));
const BookNow = React.lazy(() => import('../containers/AutomobilePage/bookNow'));

// CART AND CHECKOUT AND WISHLISTS
const Cartpage = React.lazy(() => import('../containers/cartpage'));
const ConfirmOrder = React.lazy(() => import('../containers/confirmOrderPage'));

// AUTHS
const LoginPage = React.lazy(() => import('../containers/auth/LoginPage'));
const RegisterPage = React.lazy(() => import('../containers/auth/RegisterPage'));
const VerifiedEmail = React.lazy(() => import('../containers/profile/verifiedEmail'));
const VerifyEmail = React.lazy(() => import('../containers/profile/verifyEmail'));

// Pages
const AboutUs = React.lazy(() => import('../containers/pages/aboutUs'));
const TermsConditions = React.lazy(() => import('../containers/pages/termsConditions'));
const PrivacyPolicy = React.lazy(() => import('../containers/pages/privacyPolicy'));
const RefundPolicy = React.lazy(() => import('../containers/pages/refundPolicy'));
const Faq = React.lazy(() => import('../containers/pages/faq'));
const ContactPage = React.lazy(() => import('../containers/pages/contact'));
const ResetPassword = React.lazy(() => import('../containers/auth/resetPassword'));
const UserConfirm = React.lazy(() => import('../containers/auth/userConfirm'));

// Business/Vacancy
const BusinessOwnerVacancyList = React.lazy(() => import('../containers/career/containers/BusinessOwnerVacancyList'));
const BusinessesList = React.lazy(() => import('../containers/career/containers/BusinessesList'));
const ManageBusiness = React.lazy(() => import('../containers/career/containers/ManageBusiness'));

const UserProfile = React.lazy(() => import('../containers/pages/userProfile'));
const MyConnections = React.lazy(() => import('../containers/profile/connections'));
const Contests = React.lazy(() => import('../containers/profile/contests'));
const contestDetail = React.lazy(() => import('../containers/profile/contestDetail'));
const StorePage = React.lazy(() => import('../containers/pages/StorePage'));
const MyApplications = React.lazy(() => import('../containers/profile/applications'));
const CompareAutomobiles = React.lazy(() => import('../containers/products/CompareAutomobiles'));

// Quizzes
const QuizLanding = React.lazy(() => import('../containers/quiz/QuizLanding'));
const QuizPlay = React.lazy(() => import('../containers/quiz/QuizPlay'));
const ManageQuizzes = React.lazy(() => import('../containers/quiz/ManageQuizzes'));
const QuizForm = React.lazy(() => import('../containers/quiz/QuizForm'));
const QuizStats = React.lazy(() => import('../containers/quiz/QuizStats'));

// Contest
const UGContest = React.lazy(() => import('../containers/ug-contest/containers'));
const ContestantDetail = React.lazy(() => import('../containers/ug-contest/containers/ContestantDetail'));
const UGContestList = React.lazy(() => import('../containers/ug-contest/containers/ContestList'));
const Career = React.lazy(() => import('../containers/career/containers'));
const CareerDetail = React.lazy(() => import('../containers/career/containers/CareerDetail'));

// Blogs
const BlogsList = React.lazy(() => import('../containers/blog'));
const BlogPage = React.lazy(() => import('../containers/blog/BlogPage'));

// PROFILE PAGES
const ProfilePage = React.lazy(() => import('../containers/profile/profile'));
const EditProfilePage = React.lazy(() => import('../containers/profile/profileEdit'));
const Wishlist = React.lazy(() => import('../containers/profile/profileWishlist'));
const Promotion = React.lazy(() => import('../containers/profile/promotion'));
const ChangePassword = React.lazy(() => import('../containers/profile/profileChangePassword'));
const SupportTickets = React.lazy(() => import('../containers/profile/profileSupportTickets'));

const Address = React.lazy(() => import('../containers/profile/address'));

const OrdersPage = React.lazy(() => import('../containers/profile/order'));
const OrderViewPage = React.lazy(() => import('../containers/profile/orderView'));

// Auctions
const Auctions = React.lazy(() => import('../containers/auction/containers'));
const AuctionDetail = React.lazy(() => import('../containers/auction/containers/AuctionDetail'));
const MyAuctionSellingBids = React.lazy(() => import('../containers/auction/containers/MyAuctionSellingBids'));
const MyAuctionSellingOffers = React.lazy(() => import('../containers/auction/containers/MyAuctionSellingOffers'));
const AuctionSellProduct = React.lazy(() => import('../containers/auction/containers/AuctionSellProduct'));
const MyAuction = React.lazy(() => import('../containers/auction/containers/MyAuction'));
const MyAuctionBuyingBids = React.lazy(() => import('../containers/auction/containers/MyAuctionBuyingBids'));
const MyAuctionBuyingBidsList = React.lazy(() => import('../containers/auction/containers/MyAuctionBuyingBidsList'));
const MyAuctionBuyingOffersList = React.lazy(() => import('../containers/auction/containers/MyAuctionBuyingOffersList'));

const CampaignPage = React.lazy(() => import('../containers/Campaign'));
const CampaignCategoriePage = React.lazy(() => import('../containers/Campaign/CategoriePage'));
const CampaignBrandPage = React.lazy(() => import('../containers/Campaign/BrandPage'));
const SellAtUGPage = React.lazy(() => import('../containers/pages/sellAtUGPage'));
const BeASeller = React.lazy(() => import('../containers/pages/beASeller'));

function PublicRouter() {
  return (
    <>
      <Router history={history}>
        <Suspense fallback={<AppLoadingLayout><h3>Loading Please Wait</h3></AppLoadingLayout>}>
            <WindowScroller />
            <Switch>
                <PublicRoute exact path="/" component={Homepage} />{' '}
                <PublicRoute exact path="/search" component={SearchPage} />{' '}
                <PublicRoute path="/category/:slug" component={ProductPage} />{' '}
                <PublicRoute exact path="/categories/" component={CategoriesPage} />{' '}
                <PublicRoute path="/categories/:slug" component={CategoriesPage} />{' '}
                <PublicRoute path="/cakes" component={ProductPage} />{' '}
                <PublicRoute exact path="/products" component={ProductPage} />{' '}
                <PublicRoute path="/product/:id" component={ProductSingle} />
                <PublicRoute path="/product/:slug" component={ProductSingle} />
                <PublicRoute path="/automobiles" component={AutomobilesLanding} />
                <PublicRoute exact path="/store/:slug" component={StorePage} />
                <PublicRoute path="/store/:slug/products" component={ProductPage} />
                <PublicRoute
                    path="/automobile/brand/:slug"
                    component={AutomobilesBrandAndTypePage}
                />
                <PublicRoute
                    path="/automobile/type/:slug"
                    component={AutomobilesBrandAndTypePage}
                />
                <PublicRoute
                    path="/automobile/finder"
                    component={AutomobileFinderPage}
                />
                <Redirect from='/urgent-cakes' to='/products/urgent' />
                <PublicRoute path="/products/urgent" component={UrgentProductPage} />
                <PublicRoute path="/find-cakes" component={CakeFinder} />
                <PublicRoute
                    path="/occasion/:slug"
                    component={SpecialOccasion}
                />{' '}
                <PublicRoute path="/automobile/blogs" exact component={BlogsList} />{' '}
                <PublicRoute path="/automobile/blog/:slug" component={BlogPage} />{' '}
                <PublicRoute path="/automobile/booknow" exact component={BookNow} />
                <PublicRoute path="/automobile/:id" component={AutomobileSingle} />
                <PublicRoute path="/automobile/:slug" component={AutomobileSingle} />
                <PublicRoute exact path="/compare" component={CompareProducts} />
                <PublicRoute path="/compare/automobile" component={CompareAutomobiles} />
                <PublicRoute path="/cart" component={Cartpage} />{' '}
                <PublicRoute path="/order-confirmation" component={ConfirmOrder} />
                <PublicRoute path="/donations" component={Donations} />{' '}
                <PublicRoute path="/roulette/:slug" component={Roulette} />{' '}
                <PublicRoute path="/products/:slug" component={ProductCollection} />{' '}
                <PrivateRoute path="/profile" exact component={ProfilePage} />{' '}
                <PrivateRoute path="/profile/edit" component={EditProfilePage} />{' '}
                <PrivateRoute path="/profile/connections" component={MyConnections} />{' '}
                <PrivateRoute path="/profile/orders" exact component={OrdersPage} />
                <PrivateRoute path="/profile/applications" exact component={MyApplications} />
                <PrivateRoute
                    path="/profile/tickets"
                    exact
                    component={SupportTickets}
                />{' '}
                <PrivateRoute
                    path="/profile/orders/view-order/:id"
                    component={OrderViewPage}
                    render={props => <OrderViewPage {...props} history={history} />}
                />{' '}
                <PrivateRoute path="/profile/wishlist" component={Wishlist} />{' '}
                <PrivateRoute path="/profile/promotion" component={Promotion} />{' '}
                <PrivateRoute path="/profile/address" component={Address} />{' '}
                <PrivateRoute
                    exact
                    path="/profile/quizzes"
                    component={ManageQuizzes}
                />
                <PrivateRoute path="/profile/quizzes/addnew" component={QuizForm} />
                <PrivateRoute
                    path="/profile/quizzes/stats/:slug"
                    component={QuizStats}
                />
                <PrivateRoute path="/profile/quizzes/:slug" component={QuizForm} />
                <PrivateRoute exact path="/profile/contests" component={Contests} />
                <PrivateRoute path="/profile/contests/:id" component={contestDetail} />
                {/* <PublicRoute exact path="/auctions" component={Auctions} />
                <PrivateRoute
                    path="/profile/auction/add-new/"
                    component={AuctionSellProduct}
                />
                <PrivateRoute
                    path="/profile/auction/update/:id"
                    component={AuctionSellProduct}
                />
                <PrivateRoute
                    path="/profile/auction/selling-bids/:id"
                    component={MyAuctionSellingBids}
                />
                <PrivateRoute
                    path="/profile/auction/selling-offers/:id"
                    component={MyAuctionSellingOffers}
                />
                <PrivateRoute
                    path="/profile/auction/my-auction"
                    component={MyAuction}
                />
                <PrivateRoute
                    path="/profile/auction/buying/bid-offer"
                    component={MyAuctionBuyingBids}
                />
                <PrivateRoute
                    path="/profile/auction/buying/bid/:id"
                    component={MyAuctionBuyingBidsList}
                />
                <PrivateRoute
                    path="/profile/auction/buying/offer/:id"
                    component={MyAuctionBuyingOffersList}
                />
                <PublicRoute path="/auction/:id" component={AuctionDetail} />
                <PrivateRoute
                    path="/profile/auction/:slug"
                    component={AuctionSellProduct}
                /> */}
                <PrivateRoute
                    path="/profile/vacancy"
                    component={BusinessOwnerVacancyList}
                />
                <PrivateRoute path="/profile/businesses" component={BusinessesList} />
                <PrivateRoute
                    path="/profile/add-business"
                    component={ManageBusiness}
                />
                <PublicRoute
                    path="/profile/changepassword"
                    component={ChangePassword}
                />{' '}
                {/* <PublicRoute path="/profile/edit" component={EditProfilePage} /> */}
                {/* Footer part */}{' '}
                <PublicRoute path="/blogs" exact component={BlogsList} />{' '}
                <PublicRoute path="/blog/:slug" component={BlogPage} />
                <PublicRoute path="/blogs/category/:slug" component={BlogsList} />
                <PublicRoute path="/aboutus" component={AboutUs} />{' '}
                <PublicRoute path="/contact" component={ContactPage} />{' '}
                <PublicRoute
                    path="/termsconditions"
                    component={TermsConditions}
                />{' '}
                <PublicRoute path="/privacy-policy" component={PrivacyPolicy} />{' '}
                <PublicRoute path="/refund-policy" component={RefundPolicy} />{' '}
                <PublicRoute path="/faq" component={Faq} />
                <PublicRoute exact path="/contests" component={UGContestList} />
                <PublicRoute exact path="/contest/:slug" component={UGContest} />
                <PublicRoute
                    exact
                    path="/contest/detail/:id"
                    component={ContestantDetail}
                />
                <PublicRoute exact path="/careers" component={Career} />
                <PublicRoute path="/career/:id" component={CareerDetail} />
                <PublicRoute exact path="/quiz" component={QuizLanding} />
                <PublicRoute path="/quiz/:slug" component={QuizPlay} />
                <PublicRoute path="/user/:username" component={UserProfile} />
                <PublicRoute exact path="/campaign/:slug" component={CampaignPage} />
                <PublicRoute path="/campaign/:campaignSlug/category/:categorySlug" component={CampaignCategoriePage} />
                <PublicRoute path="/campaign/:campaignSlug/brand/:brandSlug" component={CampaignBrandPage} />
                <PublicRoute path="/sell-at-ug" component={SellAtUGPage} />
                <ErrorRoute path="/be-a-seller" component={BeASeller} />
                {/* Error Routes */}{' '}
                <ErrorRoute path="/login" component={LoginPage} />{' '}
                <ErrorRoute path="/verify/email" component={VerifyEmail} />{' '}
                <ErrorRoute path="/verified" component={VerifiedEmail} />{' '}
                <ErrorRoute path="/reset-password" component={ResetPassword} />
                <ErrorRoute path="/user-confirm" component={UserConfirm} />
                <ErrorRoute path="/register" component={RegisterPage} />
                <ErrorRoute path="/*" component={Error404} />{' '}
            </Switch>{' '}    
        </Suspense>
      </Router>{' '}
    </>
  );
}

export default PublicRouter;
