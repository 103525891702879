import store from '../store';
import {
  REMOVE_CART_ITEMS,
  CLEAR_CART_ITEMS,
  UPDATE_CART_ITEMS,
  CART_HAS_LOADED,
  CART_IS_LOADING,
  ADD_CART_ITEMS_FAILED,
  SET_CART_DELIVERY_METHOD,
  SET_CART,
  SET_CART_ITEMS,
  SET_CART_TIMESLOTS,
  SET_CART_CAKE_ACCESSORY_ITEMS,
  SET_CART_CURRENT_DELIVERY_METHOD,
  SET_CART_CURRENT_DELIVERY_ADDRESS,
  SET_CART_PAYMENT_METHODS,
  SET_PROCESS_CART_CAKE_PRICE,
  SET_CART_ID,
} from '../types';

import { apiV2, authV2 } from '../../services/api';

import {
  paymentMethodsLink,
  addToCartLink,
  placeOrderLink,
  updateToCartLink,
  updateCartLink,
  applyCouponLink,
  minCakePoundLink,
  removeFromCartLink,
  getDeliveryMethodLink,
  cakePriceCalculateLink,
  deliveryTimeslotLink,
  fetchCartLink,
  processPaymentLink,
} from '../../services/apiLink.js';

export const fetchCartItems = (cb = null) => async dispatch => {
    dispatch({
        type: CART_IS_LOADING,
    });
    try {
        const res = await authV2.get(fetchCartLink);
        if (res.data.data) {
            const { cartItems } = res.data.data;

            dispatch({
                type: SET_CART,
                payload: res.data.data.cartItems,
                note: res.data.data.note,
                unpaidOrders: res.data.data.unpaidOrders,
                gifts: res.data.data.gifts,
            });

            let items = cartItems.map(item => item.productDetails);
            items = items.flat();

            dispatch({
                type: SET_CART_ID,
                payload: res.data.data.cartId,
            });

            dispatch({
                type: SET_CART_ITEMS,
                payload: items,
            });
            if (cb) {
                cb(true, res);
            }
        } else if (cb) {
            cb(false, res);
        }
    } catch (error) {
        dispatch({
            type: SET_CART,
            payload: [],
        });
        dispatch({
            type: SET_CART_ITEMS,
            payload: [],
        });
        if (cb) {
            cb(false, error);
        }
    }
    dispatch({
        type: CART_HAS_LOADED,
    });
};

export const setCurrentDeliveryMethod = (data, cb = null) => dispatch => {
  dispatch({
    type: SET_CART_CURRENT_DELIVERY_METHOD,
    payload: data,
  });
  if (cb) {
    cb();
  }
};

export const setCurrentDeliveryAddress = (data, cb = null) => dispatch => {
  dispatch({
    type: SET_CART_CURRENT_DELIVERY_ADDRESS,
    payload: data,
  });
  if (cb) {
    cb();
  }
};

/**
    Data format
    productId:
    quantity:
    deliveryLocationId:
    remark:
    deliveryMethodId:
    timeSlot:

 */
export const addCartCakeAccessoryToCart = ( cb = null ) => async dispatch => {
  const cartAccessory = store.getState().cart.accessoryToApi;
  const deliveryLocationId = store.getState().processCart.locationAddress;
  const { deliveryDate } = store.getState().processCart;

  const newData = cartAccessory.map(item => {
    item.productId = item.id;
    item.deliveryLocationId = deliveryLocationId;
    item.deliveryDate = deliveryDate;
    item.source = 'web';
    return item;
  });

  const res = [];
  for (let i = 0; i < newData.length; i++) {
    res[i] = authV2.post(addToCartLink, newData[i]);
  }
  const resData = await Promise.all(res);
  await fetchCartItems()(dispatch);
  dispatch({
    type: SET_CART_CAKE_ACCESSORY_ITEMS,
    payload: [],
  });
  if (cb) {
    cb(true, resData);
  }
};

export const addCartCakeAccessory = (data, cb = null) => dispatch => {
  const cartAccessory = store.getState().cart.accessoryToApi;
  const productInCart = cartAccessory.filter(item => {
    return parseInt(item.id) == parseInt(data.id);
  });

  if (cartAccessory.length && productInCart.length) {
    cartAccessory.map(item => {
      if (parseInt(item.id) == parseInt(data.id)) {
        return data;
      }
      return item;
    });
  } else {
    cartAccessory.push(data);
  }
  dispatch({
    type: SET_CART_CAKE_ACCESSORY_ITEMS,
    payload: cartAccessory,
  });

  if (cb) {
    cb();
  }
};

export const clearCartCakeAccessory = (cb = null) => dispatch => {
  dispatch({
    type: SET_CART_CAKE_ACCESSORY_ITEMS,
    payload: [],
  });
  if (cb) {
    cb();
  }
};

export const removeCartCakeAccessory = (data, cb = null) => dispatch => {
  const cartAccessory = store.getState().cart.accessoryToApi;
  const newData = cartAccessory.filter(item => {
    return parseInt(item.id) !== parseInt(data.id);
  });
  dispatch({
    type: SET_CART_CAKE_ACCESSORY_ITEMS,
    payload: newData,
  });
  if (cb) {
    cb();
  }
};

export const clearCartItems = () => async dispatch => {
  dispatch({
    type: CART_IS_LOADING,
  });
  try {
    dispatch({
      type: CLEAR_CART_ITEMS,
    });
    dispatch({
      type: SET_CART_ITEMS,
      payload: [],
    });
  } catch (error) {}
  dispatch({
    type: CART_HAS_LOADED,
  });
};

export const addCartItems = (data, cb, insertData = null) => async dispatch => {
  dispatch({
    type: CART_IS_LOADING,
  });
  try {
    data.source = 'web';
    const cartItem = await authV2.post(addToCartLink, data);
    if (cartItem.data.data) {
        if(data.quickCheckout) {
            cb(true, cartItem);
            return;
        }
        const { cartItems } = cartItem.data.data;

        dispatch({
            type: SET_CART,
            payload: cartItem.data.data.cartItems,
            note: cartItem.data.data.note,
            unpaidOrders: cartItem.data.data.unpaidOrders,
        });

        let items = cartItems.map(item => item.productDetails);
        items = items.flat();

        dispatch({
            type: SET_CART_ID,
            payload: cartItem.data.data.cartId,
        });

        dispatch({
            type: SET_CART_ITEMS,
            payload: items,
        });

        if (cb) {
            cb(true, cartItem);
        }
        } else if (cb) {
            cb(false, cartItem);
        }
  } catch (error) {
    dispatch({
        type: ADD_CART_ITEMS_FAILED,
    });
    if (cb) {
        cb(false, error.response);
    }
    }
    dispatch({
            type: CART_HAS_LOADED,
    });
};

export const updateCart = (data, cb) => async dispatch => {
  dispatch({
    type: CART_IS_LOADING,
  });
  try {
    const cartItem = await authV2.post(updateCartLink, data);
    if (cartItem.data.data) {
      const { cartItems } = cartItem.data.data;

      dispatch({
        type: SET_CART,
        payload: cartItem.data.data.cartItems,
        note: cartItem.data.data.note,
        unpaidOrders: cartItem.data.data.unpaidOrders,
      });

      let items = cartItems.map(item => item.productDetails);
      items = items.flat();

      dispatch({
        type: SET_CART_ID,
        payload: cartItem.data.data.cartId,
      });

      dispatch({
        type: SET_CART_ITEMS,
        payload: items,
      });

      if (cb) {
        cb(true, cartItem);
      }
    } else if (cb) {
      cb(false, cartItem);
    }
  } catch (error) {
    dispatch({
      type: ADD_CART_ITEMS_FAILED,
    });
    if (cb) {
      cb(false, error);
    }
  }
  dispatch({
    type: CART_HAS_LOADED,
  });
};

export const applyCoupon = ( data, cb ) => async dispatch => {
    try {
        const res = await authV2.post(applyCouponLink, data);
        
        if( cb ) {
            cb( true, res );
        }
    } catch ( error ) {
        if( cb ) {
            cb( false, error.response );
        }
    }
};

export const updateCartItems = (data, cb=null) => async dispatch => {
  dispatch({
    type: CART_IS_LOADING,
  });
  try {
    const requestData = {
      product_id: data.id,
    };
    await authV2.post(updateToCartLink, requestData);
    // Get Cart Items And Replace The Data
    const cartItems = store.getState().cart.items;
    const newItems = cartItems.map((item, index) => {
      if (item.id === data.id) {
        item = data;
      }
      return item;
    });
    // Dispatch the data when successful
    dispatch({
      type: UPDATE_CART_ITEMS,
      payload: newItems,
    });
    if( cb ) {
        cb(true);
    }
  } catch (error) {
    if( cb ) {
        cb(false);
    }
  }
  dispatch({
    type: CART_HAS_LOADED,
  });
};

export const removeFromCart = (data, cb = null) => async dispatch => {
  dispatch({
    type: CART_IS_LOADING,
  });
  try {
    const requestData = {
      cartItemId: data.cartItemId,
    };

    const res = await authV2.post(removeFromCartLink, requestData);
    
    if (res.data.data) {
      // Get Cart Items And Remove The Data
      const cartItems = store.getState().cart.items;
      const newItems = cartItems.filter((item, index) => {
        return item.cartItemId !== data.cartItemId;
      });

      dispatch({
        type: REMOVE_CART_ITEMS,
        payload: newItems,
      });

      dispatch({
        type: SET_CART,
        payload: res.data.data.cartItems,
        note: res.data.data.note,
        unpaidOrders: res.data.data.unpaidOrders,
      });

      if (cb) {
        cb(true, res);
      }
    } else {
      dispatch({
        type: SET_CART,
        payload: [],
        note: res.data.data.note,
        unpaidOrders: res.data.data.unpaidOrders,
      });
      dispatch({
        type: SET_CART_ITEMS,
        payload: [],
      });
      if (cb) {
        cb(false, res);
      }
    }
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }

  dispatch({
    type: CART_HAS_LOADED,
  });
};

/** 
    format to receive 
    {
        productId:4549,
        pound:1,
        tier:1,
        height:1,
        flavorId:4,
        icingType:1,
        eggless:1
    }
 */
export const calculateCakePrice = (data, cb = null) => async dispatch => {
  try {
    const url = `productId=${data.productId}&pound=${data.pound}&tier=${data.tier}&height=${data.height}&flavorId=${data.flavor}&icingType=${data.icingType}&eggless=${data.eggless}&lessCream=${data.lessCream}`;
    const res = await apiV2.get(cakePriceCalculateLink + url);
    if (res.data.data) {
      dispatch({
        type: SET_PROCESS_CART_CAKE_PRICE,
        payload: res.data.data.productPrice,
      });

      if (cb) {
        cb(true, res);
      }
    } else if (cb) {
      cb(false, res);
    }
  } catch (error) {
    if( cb ) {
        cb( false, error.response );
    }
  }
};

/** 
    Min cake pound 
    { 
        tier:1,
        height:2
    }
 */

export const calculateMinCakePound = (data, cb = null) => async dispatch => {
  try {
    const url = `id=${data.id}&tier=${data.tier}&height=${data.height}`;
    // Get Cart Items And Remove The Data
    const pound = await apiV2.get(minCakePoundLink + url);

    if (cb) {
      cb(true, pound);
    }
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }
};

/**

    Expected data format
    {
        productId: 23,
        deliveryDate: "2017-12-22",
        deliveryLocationId: 123
    }
 */

export const fetchDeliveryMethod = (data, cb = null) => async dispatch => {
  try {
    dispatch({
      type: CART_IS_LOADING,
    });
    const res = await authV2.post(getDeliveryMethodLink, data);
    dispatch({
      type: SET_CART_DELIVERY_METHOD,
      payload: res.data.data.deliveryMethods,
    });
    if (cb) {
      cb(true, res);
    }
  } catch (error) {
    cb(false, error.response);
  }
  dispatch({
    type: CART_HAS_LOADED,
  });
};
/**
    Check for timeslot after the product has been added to cart to delivery the product.
    Expected data format @params
    {
        deliveryMethodId: 123,
        deliveryDate: "2018-12-30"
    }
 */
export const checkTimeSlotForDeliveryLink = (
  data,
  cb = null
) => async dispatch => {
  try {
    dispatch({
      type: CART_IS_LOADING,
    });
    const url = `deliveryMethodId=${data.deliveryMethodId}&deliveryDate=${data.deliveryDate}`;
    const res = await apiV2.get(deliveryTimeslotLink);
    const data = {
      date: data.deliveryDate,
      deliveryMethod: data.deliveryDate,
      timeslot: res.data.data.timeSlots,
    };
    dispatch({
      types: SET_CART_TIMESLOTS,
      payload: data,
    });
    if (cb) {
      cb(true, res);
    }
  } catch (error) {
    cb(false, error);
  }
  dispatch({
    type: CART_HAS_LOADED,
  });
};

/**
    Data Format 
    cartId:2
    sourceOfOrder:Website
    remark:
    cartItems[]:6
    cartItems[]:4
    cartItems[]:5
    cartItems[]:7
    couponCode:
 */
export const placeOrder = (data, cb = null) => async dispatch => {
  try {
    const res = await authV2.post(placeOrderLink, data);
    if (cb) {
      cb(true, res);
    }
  } catch (error) {
    if (cb) {
      cb(false, error.response);
    }
  }
};

/**
    Data format 
    orderId:22146
    paymentMethodId:10
 */

export const processPayment = (data, cb = null) => async dispatch => {
    try {
        const res = await authV2.post(processPaymentLink, data);
        if (cb) {
        cb(true, res);
        }
    } catch (error) {
        if (cb) {
        cb(false, error.response);
        }
    }
};

export const setCartPaymentMethods = data => dispatch => {
  dispatch({
    type: SET_CART_PAYMENT_METHODS,
    payload: data,
  });
};

/** 

    Payment method api
    https://staging.ugbazaar.com/api/V2/payment-methods?orderId=22079
 */

export const getPaymentMethodsFromOrder = (data, cb) => async dispatch => {
  dispatch({
    type: SET_CART_PAYMENT_METHODS,
    payload: [],
  });
  try {
    const res = await authV2.get(
      `${paymentMethodsLink}?orderId=${data.orderId}&source=web`
    );
    if (res.data.data) {
      dispatch({
        type: SET_CART_PAYMENT_METHODS,
        payload: res.data.data.paymentGateway,
        default: res.data.data.default,
      });
      if (cb) {
        cb(true, res);
      }
    } else if (cb) {
      cb(false, res);
    }
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }
};

export const removeCartFromRedux = (data, cb = null) => dispatch => {
  const cartItems = store.getState().cart.items;
  const { cart } = store.getState().cart;
  const removeDataId = data.productDetails.map(item => item.cartItemId);
  const newCartItems = cartItems.filter(item =>
    removeDataId.includes(item.cartItemId)
  );
  const cartData = cart.filter(item => item.splitCartId != data.splitCartId);

  dispatch({
    type: SET_CART,
    payload: cartData,
  });

  dispatch({
    type: SET_CART_ITEMS,
    payload: newCartItems,
  });

  if (cb) {
    cb(true, {
      newCartItems,
      cartData,
    });
  }
};

export const getCakeQuickInfo = (id, cb = null) => async dispatch => {
    try {
        const res = await apiV2.get(`/product/quick-info/${id}`);
        if (cb) {
            cb(true, res);
        }
    } catch (error) {
        if (cb) {
            cb(false, error.response);
        }
    }
}