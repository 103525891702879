import {
  SET_BLOG_ITEMS,
  SET_BLOG_IS_LOADING,
  SET_BLOG_HAS_LOADED,
  SET_BLOG_ITEM,
} from '../types';

const initState = {
  items: null,
  item: null,
  loading: false,
  categories: [],
};

const blogReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_BLOG_ITEMS:
      return {
        ...state,
        items: action.payload,
      };

    case SET_BLOG_ITEM:
      return {
        ...state,
        item: action.payload,
      };

    case SET_BLOG_IS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case SET_BLOG_HAS_LOADED:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default blogReducer;
