import BidIcon from './BidIcon';
import SupportIcon from './SupportIcon';
import TicketIcon from './TicketIcon';
import OfferIcon from './OfferIcon';
import ApplicationsIcon from './ApplicationsIcon';
import QuizIcon from './QuizIcon';
import AutoIcon from './AutoIcon';
import AuctionIcon from './AuctionIcon';
import VacancyIcon from './VacancyIcon';
import PublicProfileIcon from './PublicProfileIcon';
import ContestIcon from './ContestIcon';
import RuIcon from './RuIcon';
import ShovelIcon from './ShovelIcon';

export {
    BidIcon,
    SupportIcon,
    TicketIcon,
    OfferIcon,
    ApplicationsIcon,
    QuizIcon,
    AutoIcon,
    AuctionIcon,
    VacancyIcon,
    PublicProfileIcon,
    ContestIcon,
    RuIcon,
    ShovelIcon,
}