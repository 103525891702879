/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
// This route holds all the frontend layout
import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PublicLayout from '../containers/wrappers/public';
import ErrorLayout from '../containers/wrappers/error';
import AppLoadingLayout from '../containers/wrappers/loading';
import { useSelector } from 'react-redux';
import { UGContext } from '../config';

const PublicRoute = ({ component: Component,location, ...rest }) => {

    const config = useContext(UGContext);
    
    if (location.pathname === '/cakes') {
        return <Redirect to={{
            pathname: "/category/cakes",
            state: { referrer: location }
        }} />
    }

    return (
        <Route
            {...rest}
            render={matchProps => (
                <PublicLayout>
                    <Component {...matchProps} />
                </PublicLayout>
            )}
        />
    );
};

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { isLoggedIn } = useSelector(state => state.user);
  let referrer = window.location.href;
  referrer = referrer.replace( window.location.origin, '' );
  if (localStorage.getItem('ugcake_access_token') == null) {
    return <Redirect to={{
      pathname: "/login",
      state: { referrer }
    }} />;
  }
  
  return isLoggedIn ? (
    <Route
      {...rest}
      render={matchProps => (
        <PublicLayout>
          <Component {...matchProps} />
        </PublicLayout>
      )}
    />
  ) : (
      <AppLoadingLayout>
        <h3>Loading Please Wait</h3>
      </AppLoadingLayout>
    );
};

// Error Routes Layout

const ErrorRoute = (props) => {
  return (
    <Route
      {...props}
      render={matchProps => (
        <ErrorLayout>
          <Component {...matchProps} />
        </ErrorLayout>
      )}
    />
  );
};

export { 
    PublicRoute, 
    ErrorRoute, 
    PrivateRoute 
};
