import {
    CLEAR_CATEGORY_ITEMS,
    SET_CATEGORY_IS_LOADING,
    SET_CATEGORY_HAS_LOADED,
    SET_CATEGORY_ITEMS,
    SET_CATEGORY_BRANDS,
} from "./../types";

const initState = {
    items: [],
    loading: false,
    brands: []
};

const categoryReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_CATEGORY_ITEMS:
            return {
                ...state,
                items: action.payload
            };

        case SET_CATEGORY_BRANDS:
            return {
                ...state,
                brands: action.payload
            };

        case CLEAR_CATEGORY_ITEMS:
            return {
                ...state,
                items: []
            };

        case SET_CATEGORY_IS_LOADING:
            return {
                ...state,
                loading: true
            };

        case SET_CATEGORY_HAS_LOADED:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }

    return state;
};
export default categoryReducer;