import React from 'react';
import { Icon } from 'antd';

const ShovelSvg = () => (
    <svg width="1em" height="1em" fill='currentColor' viewBox="0 0 511.856 511.856">
        <g>
            <path d="m270.622 319.007-27.38 27.39 19.69 59.08c1.72 5.15.52 10.83-3.13 14.84-1.23 1.35-30.54 33.31-73.93 59.16-51.601 30.726-110.507 44.743-165.21 18.84-3.107-1.464-5.657-4.005-7.13-7.13-23.06-48.68-16.54-105.81 18.84-165.21 25.85-43.39 57.81-72.7 59.16-73.93 4.01-3.65 9.69-4.85 14.84-3.13l59.08 19.69 27.39-27.38c5.86-5.86 15.36-5.86 21.21 0l56.57 56.57c5.86 5.85 5.86 15.35 0 21.21z" fill="#eef4ff"/>
            <path d="m270.622 319.007-27.38 27.39 19.69 59.08c1.72 5.15.52 10.83-3.13 14.84-1.23 1.35-30.54 33.31-73.93 59.16-51.601 30.726-110.507 44.743-165.21 18.84-1.57-.74-2.98-1.74-4.18-2.95l225.86-225.85 28.28 28.28c5.86 5.85 5.86 15.35 0 21.21z" fill="#d9e6fc"/>
            <path d="m413.632 175.997-142.89 142.89-77.78-77.78 142.89-142.89c5.86-5.86 15.36-5.86 21.22 0 17.999 17.999 38.56 38.561 56.56 56.56 5.86 5.861 5.86 15.361 0 21.22z" fill="#e27a4e"/>
            <path d="m413.632 175.997-142.89 142.89-38.89-38.89 153.5-153.5 28.28 28.28c5.86 5.861 5.86 15.361 0 21.22z" fill="#dc455b"/>
            <path d="m479.752 132.537-61.91 39.25-9.45 9.45c-9.481-9.472-68.341-68.271-77.82-77.74l9.49-9.49 39.25-61.91c4.44-7 13.7-9.07 20.7-4.64 7 4.44 9.07 13.71 4.64 20.7l-33.7 53.16 39.58 39.58 53.16-33.7c6.99-4.44 16.26-2.36 20.7 4.64 4.43 6.991 2.36 16.261-4.64 20.7z" fill="#a4d772"/>
            <path d="m479.752 132.537-61.91 39.25-9.45 9.45-38.91-38.87 21.26-21.26 19.79 19.79 53.16-33.7c6.99-4.44 16.26-2.36 20.7 4.64 4.43 6.991 2.36 16.261-4.64 20.7z" fill="#7baf6b"/>
            <path d="m507.462 155.607c-5.824 5.825-15.339 5.882-21.22 0l-130-130c-5.85-5.86-5.85-15.36 0-21.22 5.86-5.85 15.36-5.85 21.22 0l130 130c5.85 5.86 5.85 15.36 0 21.22z" fill="#90776f"/>
            <path d="m507.462 155.607c-5.824 5.825-15.339 5.882-21.22 0l-65-65 21.22-21.22 65 65c5.85 5.86 5.85 15.36 0 21.22z" fill="#674d55"/>
        </g>
    </svg>
)

const ShovelIcon = props => <Icon component={ShovelSvg} {...props} />
export default ShovelIcon;