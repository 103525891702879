import {
  ADD_AUTOMOBILE_ITEMS,
  SET_AUTOMOBILE_ITEMS,
  SET_AUTOMOBILE_IS_LOADING,
  SET_AUTOMOBILE_HAS_LOADED,
  FLUSH_AUTOMOBILE_ITEMS,
  SET_AUTOMOBILE_CATEGORIES,
  ADD_AUTOMOBILE_CATEGORIES,
  SET_AUTOMOBILE_ITEM,
  SET_AUTOMOBILE_BRAND,
  SET_AUTOMOBILE_BRANDLIST,
  SET_AUTOMOBILE_TYPELIST,
  SET_FAVORITE_AUTOMOBILE,
  SET_SERVICE_BLOG_AUTOMOBILE,
  ADD_FAVORITE_AUTOMOBILE,
  REMOVE_FAVORITE_AUTOMOBILE
} from "./../types";

const initState = {
  items: [],
  loading: false,
  categories: {},
  item: {},
  brandList: [],
  typeList: [],
  brand: [],
  automobileWishLists: [],
  automobileReviews: [],
  serviceNBlogs: {},
};

const automobileReducer = (state = initState, action) => {
  switch (action.type) {
    case ADD_AUTOMOBILE_ITEMS:
      return {
        ...state,
        items: [...state.items, ...action.payload]
      };

    case SET_AUTOMOBILE_ITEM:
      return {
        ...state,
        item: action.payload,
        automobileReviews: []
      };

    case ADD_AUTOMOBILE_CATEGORIES:
      return {
        ...state,
        categories: [...state.categories, action.payload]
      };

    case SET_AUTOMOBILE_CATEGORIES:
      return {
        ...state,
        categories: action.payload
      };

    case SET_AUTOMOBILE_BRAND:
      return {
        ...state,
        brand: action.payload
      };
    case FLUSH_AUTOMOBILE_ITEMS:
      return {
        ...state,
        items: []
      };

    case SET_AUTOMOBILE_ITEMS:
      return {
        ...state,
        items: action.payload,
      };

    case SET_AUTOMOBILE_IS_LOADING:
      return {
        ...state,
        loading: true
      };

    case SET_AUTOMOBILE_HAS_LOADED:
      return {
        ...state,
        loading: false
      };
    case SET_AUTOMOBILE_BRANDLIST:
      return {
        ...state,
        brandList: action.payload
      };
    case SET_AUTOMOBILE_TYPELIST:
      return {
        ...state,
        typeList: action.payload
      };
    case SET_FAVORITE_AUTOMOBILE:
      return {
        ...state,
        automobileWishLists: action.payload
      }

    case ADD_FAVORITE_AUTOMOBILE:
        return {
            ...state,
            automobileWishLists: [...state.automobileWishLists, action.payload],
        }

    case REMOVE_FAVORITE_AUTOMOBILE:
        return {
            ...state,
            automobileWishLists: state.automobileWishLists.filter(
                item => parseInt(item) !== parseInt(action.payload)
            ),
        };

    case SET_SERVICE_BLOG_AUTOMOBILE:
        return {
            ...state,
            serviceNBlogs: action.payload
        }
    default:
      return state;
  }
};
export default automobileReducer;