import React from 'react';
import { Icon } from 'antd';

const RuSvg = () => (
    <svg width="1em" height="1em" fill='currentColor' viewBox="0 0 64 64">
        <g transform="translate(-347 -199)">
            <rect width="64" height="64" transform="translate(347 199)" fill="none"/>
            <g transform="translate(403.413 199.509)">
                <path d="M.7,11.6H-24.719a11.487,11.487,0,0,1,3.428,3.385,8.475,8.475,0,0,1,1.327,4.737A10.5,10.5,0,0,1-21.52,25.5a13.576,13.576,0,0,1-4.664,4.557,12.6,12.6,0,0,1-6.949,2.12h-3.017A2.923,2.923,0,0,1-34.5,33.449a5.881,5.881,0,0,1,.547,2.912V46.553a5.624,5.624,0,0,0,1.416,3.867,4.718,4.718,0,0,0,3.706,1.592,3.7,3.7,0,0,0,3.062-1.643,5.961,5.961,0,0,0,1.234-3.655V43.236l7.13,2.105a11.963,11.963,0,0,1-4.159,6.353,11.219,11.219,0,0,1-7.176,2.424,12.208,12.208,0,0,1-8.735-3.442,11.274,11.274,0,0,1-3.609-8.494v-5.6a3.915,3.915,0,0,0-1.555-3.12,5.185,5.185,0,0,0-3.381-1.287h-2.011a3.627,3.627,0,0,0-2.288,1.188v-4.3H-37.61a11.038,11.038,0,0,0,7.449-2.637A8.394,8.394,0,0,0-27.1,19.787a9.369,9.369,0,0,0-1.875-5.593A5.777,5.777,0,0,0-33.864,11.6H-50.413V8.491H.7ZM-16.721,32.814a6.16,6.16,0,0,0,2.057,2.469,8.558,8.558,0,0,1-5.118-4.3,10.268,10.268,0,0,1-1.279-4.937A7.02,7.02,0,0,1-17.5,19.556a7.706,7.706,0,0,1,3.476-.823,11.329,11.329,0,0,1,6.678,2.379,17.581,17.581,0,0,1,5.3,6.217A20.234,20.234,0,0,1-.354,31.806a17.563,17.563,0,0,1,.6,4.208A12.632,12.632,0,0,1-.764,41.137a8.223,8.223,0,0,1-3.476,3.749L-7.8,47.168l-3.661-6.4A10.563,10.563,0,0,0-6.251,39.4,6.951,6.951,0,0,0-3.69,36.652,7.7,7.7,0,0,0-2.778,33a8.738,8.738,0,0,0-1.2-4.386A8.6,8.6,0,0,0-7.222,25a8.145,8.145,0,0,0-4.354-1.327,5.9,5.9,0,0,0-3.239,1.1,6.307,6.307,0,0,0-2.594,5.213l.1,1.37Z" transform="translate(0 0)"/>
            </g>
        </g>
    </svg>
)

const RuIcon = props => <Icon component={RuSvg} {...props} />
export default RuIcon;