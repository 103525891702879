import {
    ADD_REVIEW,
    REVIEW_SUCCESS,
    COMPLAIN_SUCCESS,
    ADD_COMPLAIN
} from "./../types";

const initState = {
    rating: 0,
    reviewStatus: false,
    complainStatus: false,
};

const reviewReducer = (state = initState, action) => {
    switch (action.type) {
        case ADD_REVIEW:
            return {
                ...state,
                reviewStatus: false,
            }
        case REVIEW_SUCCESS:
            return {
                ...state,
                reviewStatus: true
            }
        case COMPLAIN_SUCCESS:
            return {
                ...state,
                complainStatus: true
            }
        case ADD_COMPLAIN:
            return {
                ...state,
                complainStatus: false
            }

        default:
            return state;
    }
};
export default reviewReducer;