import {
  SET_PROCESS_CART_DELIVERY_METHOD,
  SET_PROCESS_CART_PRODUCT_TYPE,
  SET_PROCESS_CART_STEP,
  SET_PROCESS_CART_CUSTOMIZATION,
  SET_PROCESS_CART_DELIVERY_DATE,
  SET_PROCESS_CART_SHIPPING_ADDRESS,
  SET_PROCESS_CART_DELIVERY_TIME,
  SET_PROCESS_CART_PRODUCT_QUANTITY,
  SET_PROCESS_CART_CURRENT_ORDER_ID,
  SET_PROCESS_CART_DELIVERY_LOCATION_ADDRESS,
  SET_PROCESS_CART_FIRST_DELIVERY_DATE,
  SET_PROCESS_CART_CAKE_PRICE,
  SET_PROCESS_CART_CURRENT_ADDRESS,
} from '../types';

// function formatDate() {
//   let d = new Date(new Date().getTime() + 2 * 86400000);
//         var month = "" + (d.getMonth() + 1);
//         var day = "" + d.getDate();
//         var year = d.getFullYear();

//   if (month.length < 2) month = '0' + month;
//   if (day.length < 2) day = '0' + day;

//   return [year, month, day].join('-');
// }

/**
  Step is for stepper form,
  customization stores cake customized data
  shipping address is current selected shipping address
 */
const initState = {
  deliveryMethod: null,
  productType: 'product',
  shippingAddress: {},
  step: 1,
  customization: {},
  cakePrice: null,
  deliveryDate: null,
  deliveryTime: null,
  productQuantity: 1,
  currentOrderIds: [],
  locationAddress: 11,
  firstDeliveryDate: null,
  gps: {
    lat: 27.6981182,
    lng: 85.3328845,
  },
};

const processCartReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_PROCESS_CART_CURRENT_ADDRESS:
      return {
        ...state,
        gps: action.payload,
      };

    case SET_PROCESS_CART_CAKE_PRICE:
      return {
        ...state,
        cakePrice: action.payload,
      };

    case SET_PROCESS_CART_DELIVERY_METHOD:
      return {
        ...state,
        deliveryMethod: action.payload,
      };
    case SET_PROCESS_CART_DELIVERY_LOCATION_ADDRESS:
      return {
        ...state,
        locationAddress: action.payload,
      };
    case SET_PROCESS_CART_FIRST_DELIVERY_DATE:
      return {
        ...state,
        firstDeliveryDate: action.payload,
      };
    case SET_PROCESS_CART_PRODUCT_TYPE:
      return {
        ...state,
        productType: action.payload,
      };

    case SET_PROCESS_CART_STEP:
      return {
        ...state,
        step: action.payload,
      };

    case SET_PROCESS_CART_CUSTOMIZATION:
      return {
        ...state,
        customization: action.payload,
      };
    case SET_PROCESS_CART_DELIVERY_DATE:
      return {
        ...state,
        deliveryDate: action.payload,
      };

    case SET_PROCESS_CART_SHIPPING_ADDRESS:
      return {
        ...state,
        shippingAddress: action.payload,
      };

    case SET_PROCESS_CART_DELIVERY_TIME:
      return {
        ...state,
        deliveryTime: action.payload,
      };

    case SET_PROCESS_CART_PRODUCT_QUANTITY:
      return {
        ...state,
        productQuantity: parseInt(action.payload),
      };
    case SET_PROCESS_CART_CURRENT_ORDER_ID:
      return {
        ...state,
        currentOrderIds: action.payload,
      };
    default:
      return state;
  }
};

export default processCartReducer;
