import React from 'react';
import { Icon } from 'antd';

const BidSvg = () => (
    <svg width="1em" height="1em" fill='currentColor' viewBox="0 0 48.325 57.688">
        <path d="M-309.848,800.883V776.948h12.711l.165,1.368a11.145,11.145,0,0,0,7.34-5.375,2.73,2.73,0,0,1,3.041-1.639,34.559,34.559,0,0,0,3.573.025v-6.3c-2.278,0-4.574.026-6.869-.008a41.706,41.706,0,0,1-5.452-.233,6.21,6.21,0,0,1-5.216-6.173q-.039-3.806,0-7.612a6.231,6.231,0,0,1,6.029-6.251q13.419-.134,26.841,0a6.38,6.38,0,0,1,6.133,6.348c.041,2.314.023,4.63.011,6.944a6.6,6.6,0,0,1-6.914,6.984c-1.78.029-3.561.008-5.342.009h-5.317v6.261h8.094c.574,3.062-.487,5.495-2.488,7.9h3.818v23.153c-9.069-.551-18.227,1.282-26.895-2.022a7,7,0,0,1-1.818.534C-302.173,800.908-305.948,800.883-309.848,800.883Zm28.58-39.491v.013q6.339,0,12.678,0c2.221,0,3.419-1.1,3.473-3.307q.081-3.334-.01-6.671a3.013,3.013,0,0,0-3.306-3.188q-12.611-.011-25.222,0a3.065,3.065,0,0,0-3.394,3.368c-.016,2.091-.006,4.181,0,6.272,0,2.356,1.146,3.508,3.506,3.513Q-287.407,761.4-281.268,761.393Zm6.446,13.535c-3.912,0-7.468-.021-11.022.031a2.114,2.114,0,0,0-1.133.683,36.157,36.157,0,0,1-4.172,3.865,46.489,46.489,0,0,1-5.862,2.841c0,4.11-.019,8.463.036,12.815a1.446,1.446,0,0,0,.806,1.016,40.163,40.163,0,0,0,5.008,1.713c1.77.432,3.605.6,5.471.888v-6.1l-5.382,1.958c-.107-.94-.177-1.508-.234-2.078-.055-.552-.1-1.105-.14-1.631,4.178-1.005,5.843-3.144,5.836-7.327,0-1.437,0-2.874,0-4.357C-281.727,779.041-277.573,779.217-274.822,774.928Zm-31.417,5.457v16.9h5.6v-16.9Zm24.2,11.721h8.7v-2.829h-8.7Zm8.78,3.75H-282v2.772h8.738Zm-8.846-10.273h8.72v-2.807h-8.436Z" transform="translate(309.848 -744.681)" /><path d="M-218.73,776.131l-4.636-3.48,2.106-2.839,2.663,1.95,4.115-4.758,2.612,2.712Z" transform="translate(246.465 -761.042)" />
    </svg>
)

const BidIcon = props => <Icon component={BidSvg} {...props} />
export default BidIcon;