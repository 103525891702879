import { throttle } from 'lodash';
import {
  ADD_PRODUCT_ITEMS,
  SET_PRODUCT_ITEMS,
  SET_FILTER_FILTER_BOX,
  SET_PRODUCT_ITEM,
  SET_PRODUCT_IS_LOADING,
  SET_PRODUCT_HAS_LOADED,
  FLUSH_PRODUCT_ITEMS,
  SET_PRODUCT_COMBO,
  SET_CAKE_ACCESSORY,
  SET_PRODUCT_COMPARE,
  SET_HOME_LOADED_CATEGORIES,
  ADD_PRODUCT_COMPARE_DATA,
  SET_URGENT_PRODUCTS_HAS_LOADED,
  SET_URGENT_PRODUCTS_IS_LOADING,
  SET_URGENT_PRODUCTS,
  SET_DONATION_ITEMS,
} from '../types';

import {
  getProductFromCategory,
  getProductDetail,
  cakeAccessoryLink,
  getProductComboLink,
  getCakeShapesLink,
  getUrgentProducts,
  getCakeCategoriesLink,
  getSuggestedCakesLink,
  digTreasureLink,
  getReviewsLink,
  multipleProductsLink,
  getDonationProductLink,
  productCollectionLink
} from '../../services/apiLink';

import { apiV2, authV2 } from '../../services/api';
import store from '../store';

export const flushProducts = () => dispatch => {
  dispatch({
    type: FLUSH_PRODUCT_ITEMS,
  });
};

/**
    Data format 
    {   
        category: 2,
        page: 1
    }
 */
export const fetchCategoryProduct = (data, cb = null) => async dispatch => {
  try {
    dispatch({
      type: SET_PRODUCT_IS_LOADING,
    });
    const res = await apiV2.get(
      `${getProductFromCategory + data.category}?page=${data.page ||
        1}&perPage=24`
    );

    if (res.data.data) {
      const id = data.category;
      const apiDataItems = res.data.data.products.map(item => {
        item.category_id = id;
        return item;
      });
      dispatch({
        type: ADD_PRODUCT_ITEMS,
        payload: apiDataItems,
        pagination:
          (res.data && res.data.meta && res.data.meta.pagination) || {},
      });
      dispatch({
        type: SET_FILTER_FILTER_BOX,
        payload: res.data.data.filterbox,
      });
      dispatch({
        type: SET_HOME_LOADED_CATEGORIES,
        payload: {
          id,
          page: data.page,
        },
      });
      if (cb) {
        cb(true, res);
      }
    } else if (cb) {
      cb(false, res);
    }
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }

  dispatch({
    type: SET_PRODUCT_HAS_LOADED,
  });
};

export const fetchCategoryProductWithFilter = (slug, queries, cb = null, loadMore=false) => async dispatch => {
  try {
    dispatch({
      type: SET_PRODUCT_IS_LOADING,
    });
    const res = await apiV2.get( getProductFromCategory + slug + queries );

    if (res.data.data) {
      dispatch({
        type: loadMore ? ADD_PRODUCT_ITEMS : SET_PRODUCT_ITEMS,
        payload: res.data.data.products,
      });
      dispatch({
        type: SET_FILTER_FILTER_BOX,
        payload: res.data.data.filterbox,
      });
      if (cb) {
        cb(true, res);
      }
    } else if (cb) {
      cb(false, res);
    }
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }

  dispatch({
    type: SET_PRODUCT_HAS_LOADED,
  });
};

export const fetchProductDetail = (slug, forceInactive, cb = null) => async dispatch => {
  try {
    dispatch({
      type: SET_PRODUCT_IS_LOADING,
    });
    
    const res = await authV2.get(getProductDetail(slug, forceInactive));
    if ( typeof res.data.data !== 'undefined') {
      const product = res.data.data;
      dispatch({
        type: SET_PRODUCT_ITEM,
        payload: product,
      });
      if (cb) {
        cb(true, res);
      }
    } else if (cb) {
      cb(false, res);
    }
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }

  dispatch({
    type: SET_PRODUCT_HAS_LOADED,
  });
};

export const fetchProductReviews = (id, cb = null) => async dispatch => {
    try {  
        const res = await authV2.get( getReviewsLink( id, 'product') );
        
        if (cb) {
          cb(true, res);
        }
    } catch (error) {
        if (cb) {
            cb(false, error);
        }
    }
};

export const setCompareProduct = data => dispatch => {
  dispatch({
    type: SET_PRODUCT_COMPARE,
    payload: data,
  });
};

export const compareProduct = (id, cb = null) => dispatch => {
  let { compare } = store.getState().product;
  if (compare.length > 2) {
    compare = [id];
  } else {
    const containsProduct = compare.filter(
      item => parseInt(item) === parseInt(id)
    );
    if (containsProduct.length) {
      if (cb) {
        cb(false, containsProduct);
      }
      return false;
    }
    compare = [...compare, parseInt(id)];
  }
  localStorage.setItem('ug_product_compare_item', compare);
  localStorage.setItem(
    'ug_product_compare_expiry',
    new Date(new Date().getTime() + 24 * 60 * 60 * 1000).getTime()
  );

  dispatch({
    type: SET_PRODUCT_COMPARE,
    payload: compare,
  });
  if (cb) {
    cb(true, compare);
  }
};

export const fetchCompareProducts = data =>
  throttle(async dispatch => {
    const arr = [];
    for (let i = 0; i < data.length; i++) {
      arr[i] = apiV2.get(getProductDetail + data[i]);
    }
    const res = await Promise.all(arr);
    dispatch({
      type: ADD_PRODUCT_COMPARE_DATA,
      payload: res.map(item => {
        return item.data.data;
      }),
    });
  }, 1000);

export const fetchCakeAccessory = (cb = null) => async dispatch => {
  try {
    const accessory = await apiV2.get(cakeAccessoryLink);
    dispatch({
        type: SET_CAKE_ACCESSORY,
        payload: accessory.data.data.accessories,
    });
    if (cb) {
        cb(true, accessory);
    }
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }
};

export const fetchProductCombos = (cb = null) => async dispatch => {
  try {
    const res = await apiV2.post(getProductComboLink);

    dispatch({
      type: SET_PRODUCT_COMBO,
      payload: res.data.data,
    });
    if (cb) {
      cb(true, res.data);
    }
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }
};

export const fetchUrgentProducts = (page, cb = null) => async dispatch => {
  try {
    dispatch({
      type: SET_URGENT_PRODUCTS_IS_LOADING,
    });
    const res = await apiV2.get(`${getUrgentProducts}?page=${page}&pePage=24`);
    if ( typeof res.data.data !== 'undefined') {
      dispatch({
        type: SET_URGENT_PRODUCTS,
        payload: res.data.data,
        pagination:
          (res.data && res.data.meta && res.data.meta.pagination) || {},
      });
      dispatch({
        type: SET_URGENT_PRODUCTS_HAS_LOADED,
      });
      if (cb) {
        cb(true, res.data);
      }
    }
    if (cb) {
      cb(false, res.data);
    }
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }
};

export const getCakeShapes = (cb = null) => async dispatch => {
  try {
    const res = await apiV2.get(getCakeShapesLink);
    if (cb) {
      cb(true, res);
    }
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }
};
export const getCakeCategories = (cb = null) => async dispatch => {
  try {
    const res = await apiV2.get(getCakeCategoriesLink);
    if (cb) {
      cb(true, res);
    }
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }
};
export const findCakes = (requestData, cb = null) => async dispatch => {
  try {
    const url = Object.entries(requestData)
      .map(([key, val]) => `${key}=${val}`)
      .join('&');
    const res = await apiV2.get(`${getSuggestedCakesLink}?${url}`);
    if (cb) {
      cb(true, res);
    }
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }
};

export const searchProducts = (queries, cb = null) => async dispatch => {
  try {
    const res = await authV2.get(
      `search${queries}&source=web`
    );

    const newData = res.data.data.products;
    const { pagination } = res.data.meta;
    if (cb) {
      cb(true, res, newData, pagination);
    }
  } catch (error) {
    if (cb) {
      cb(false, error.response);
    }
  }
};

export const fetchSearchHistory = (queries, cb = null) => async dispatch => {
    try {
      const res = await authV2.get( `search-history?${queries}&source=web` );
  
      if (cb) {
        cb(true, res);
      }
    } catch (error) {
      if (cb) {
        cb(false, error.response);
      }
    }
};  

export const filterProducts = (data, cb = null) => async dispatch => {
  try {
    dispatch({
      type: SET_PRODUCT_ITEMS,
      payload: [],
    });
    let url = `category/${data.category}?`;
    url += data.subcat ? `subCats=${data.subcat}` : '';
    url += data.minPrice ? `&minPrice=${data.minPrice}` : '';
    url += data.maxPrice ? `&maxPrice=${data.maxPrice}` : '';
    url += data.brands ? `&brands=${data.brands}` : '';
    url += `&sort=${data.sort || 'asc'}`;
    const res = await apiV2.get(url);

    if (res.data.data) {
      const newData = res.data.data.products;

      dispatch({
        type: SET_PRODUCT_ITEMS,
        payload: newData,
      });
      dispatch({
        type: SET_FILTER_FILTER_BOX,
        payload: res.data.data.filterbox,
      });
      if (cb) {
        cb(true, res, newData);
      }
    } else if (cb) {
      cb(false, res);
    }
  } catch (error) {
    if (cb) {
      cb(false, error);
    }
  }
};

export const digTreasure = (treasureId, productId, cb = null) => async dispatch => {
    try {
        const res = await authV2.post( digTreasureLink, { treasureId, productId } );
        if (cb) { 
            cb(true, res);
        }



    } catch (error) {
        if (cb) {
            cb(false, error.response);
        }
    }
};

export const fetchMultipleProducts = ( products, cb = null )  => async dispatch => {
    try {
        const res = await authV2.post( multipleProductsLink, { products } );
        if( cb ) {
            cb( true, res );
        }
    } catch ( error ) {
        if( cb ) {
            cb( false, error.response );
        }
    }
}

export const fetchDonationItems = (cb = null) => async dispatch => {
    try {
      const res = await apiV2.get(getDonationProductLink);
      dispatch({
          type: SET_DONATION_ITEMS,
          payload: res.data.data.items,
      });
      if (cb) {
          cb(true, res);
      }
    } catch (error) {
      if (cb) {
        cb(false, error);
      }
    }
};

export const fetchProductCollection = ( slug, cb = null ) => async dispatch => {
    try {
        const res = await authV2.get(productCollectionLink(slug));
        if( cb ) {
            cb( true, res );
        }
    } catch ( error ) {
        if( cb ) {
            cb( false, error.response );
        }
    }
}