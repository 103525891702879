import React from "react";
import UGContext from "./UGContext";

const withUGConfig = (Component) => {
    const displayName = `withUGConfig(${Component.displayName || Component.name})`;
    const C = props => {
        const { wrappedComponentRef, ...remainingProps } = props;
        return (
            <UGContext.Consumer>
                { context => {
                    return (
                        <Component
                            {...remainingProps}
                            {...context}
                            ref={wrappedComponentRef}
                            />
                    )
                }}
            </UGContext.Consumer>
        )
    }
    C.displayName = displayName;
    C.WrappedComponent = Component;
    return C;
}

export default withUGConfig;